import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { appState } from '@services/app-state';

import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

import { Apputils } from '@services/app-utils';

import { RestApiService } from '@noammazuz/vzn-feathers';

import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: "./practicequiz.component.html"
})
export class PracticequizComponent {

  practicequizForm: FormGroup;

  quizPrefix = 'prq';

  restService: any;

  weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  formContext: any;

  appState = appState;
  allHours = Apputils.allHours;
  practiceTypes = Apputils.practiceTypes;

  isLocked = false;
  isBusy = false;
  
  showErrors = false;

  morningPracticeDaysError = false;
  strengthPracticeTypeError = false;
  isotonicTimesError = false;

  beforePracticeError = false;
  onBreaksError = false;

  formOwnerUsr: any;
  isUnderNineteen = false;

  listFixedA = [
    { value: 'פרי' , text: this.translate.instant("practice.FOOD_1") },
    { value: 'תמרים' , text: this.translate.instant("practice.FOOD_2") },
    { value: 'אגוזים ושקדים' , text: this.translate.instant("practice.FOOD_3") },
    { value: 'חטיף אנרגיה' , text: this.translate.instant("practice.FOOD_4") },
    { value: "ג'ל" , text: this.translate.instant("practice.FOOD_5") },
    { value: 'פרוסת לחם לבן עם ריבה' , text: this.translate.instant("practice.FOOD_6") },
    { value: 'פרוסת לחם לבן עם שוקולד' , text: this.translate.instant("practice.FOOD_7") },
    { value: 'פרוסת לחם מלא עם ריבה' , text: this.translate.instant("practice.FOOD_8") },
    { value: 'פרוסת לחם מלא עם שוקולד' , text: this.translate.instant("practice.FOOD_9") },
    { value: 'אחר' , text: this.translate.instant("practice.FOOD_10") },
  ];
  listFixedB = [
    { value: 'פרי' , text: this.translate.instant("practice.FOOD_1") },
    { value: 'תמרים' , text: this.translate.instant("practice.FOOD_2") },
    { value: 'אגוזים ושקדים' , text: this.translate.instant("practice.FOOD_3") },
    { value: 'חטיף אנרגיה' , text: this.translate.instant("practice.FOOD_4") },
    { value: "ג'ל" , text: this.translate.instant("practice.FOOD_5") },
    // { value: 'פרוסת לחם לבן עם ריבה' , text: this.translate.instant("practice.FOOD_6") },
    // { value: 'פרוסת לחם לבן עם שוקולד' , text: this.translate.instant("practice.FOOD_7") },
    // { value: 'פרוסת לחם מלא עם ריבה' , text: this.translate.instant("practice.FOOD_8") },
    // { value: 'פרוסת לחם מלא עם שוקולד' , text: this.translate.instant("practice.FOOD_9") },
    { value: 'אחר' , text: this.translate.instant("practice.FOOD_10") },
  ];

  constructor(
    private dialogService: ConfirmDialogService,
    private restApiService: RestApiService<any>,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    private translate: TranslateService,
  ) {

    this.restService = this.restApiService.service('practicequiz');

    this.formContext = this;

    this.practicequizForm = this.formBuilder.group({
      _id: [''],

      prq_form_is_real: [false],
      prq_usr_id: [appState.user._id],

      prq_extra_practice: ['', Validators.required],
      prq_extra_practice_arr: this.formBuilder.array([]),
      prq_morning_practice: ['', Validators.required],
      // prq_morning_practice_days: this.formBuilder.array([]),
      // prq_earliest_practice_time: ['08:00'],
      prq_morning_practice_time_sunday: [''],
      prq_morning_practice_time_monday: [''],
      prq_morning_practice_time_tuesday: [''],
      prq_morning_practice_time_wednesday: [''],
      prq_morning_practice_time_thursday: [''],
      prq_morning_practice_time_friday: [''],
      prq_morning_practice_time_saturday: [''],

      prq_day_off_practice: ['', Validators.required],
      prq_practice_timing: [''],
      prq_practice_timing_days: this.formBuilder.array([]),

      prq_strength_practice: ['', Validators.required],
      prq_strength_practice_type_group: [null],
      prq_strength_practice_type_alone: [null],
      prq_strength_practice_plan: [''],
      prq_strength_practice_plan_other: [''],

      prq_drink_in_practice: ['', Validators.required],

      prq_isotonic_drinks: ['', Validators.required],
      prq_isotonic_drinks_time_arr: [null],

      prq_eat_before_practice: ['', Validators.required],
      prq_eat_before_practice_items_arr: [null],
      prq_eat_before_practice_other: [''],

      prq_eat_on_breaks: ['', Validators.required],
      prq_eat_on_breaks_items_arr: [null],
      prq_eat_on_breaks_other: [''],
    });

    // window["rf"] = this.practicequizForm;

    this.route.params.subscribe(async params => {
      if (params.id) {
        const data = await this.restService.get(params.id);
        
        // Only proceed if we have data and it matches the ID from the URL
        if (data && data._id === params.id) {
          const quizUsrId = data.prq_usr_id;
          if (!quizUsrId) {
              return;
          }
          
          const usr = data.quizOwner;
          if (!usr) {
            return;
          }
          this.formOwnerUsr = usr;
          this.isUnderNineteen = Boolean(+(usr['usr_age_formatted'] || 0) < 19);

          if (this.appState.user.usr_type === 'superadmin' || this.appState.user.usr_type === 'nutri') {
            this.appState.quizUser = usr;
          }

          if (this.translate.currentLang !== 'en') {
            if (data.quizOwner?.usr_gender === 'נקבה') {
                this.translate.use('he_F');
            } else {
                this.translate.use('he');
            }
          }

          this.isLocked = data && data.prq_locked;
          if (this.appState.user.usr_type === 'parent' || this.appState.user.usr_type === 'nutri') {
              this.isLocked = true;
          }
          if (this.appState.user.usr_type === 'nutri' && data.isReportEverPublished !== undefined) {
              this.isLocked = data.isReportEverPublished;
          }

          // Set the basic form values first
          this.practicequizForm.patchValue({
            _id: data._id,
            prq_usr_id: data.prq_usr_id
          });

          // Only populate the rest of the form if this quiz has been saved before
          if (data.savedAt || data.prq_strength_practice || data.prq_strength_practice_type_group || 
              data.prq_strength_practice_type_alone || data.prq_strength_practice_plan || 
              data.prq_strength_practice_plan_other || data.prq_isotonic_drinks || 
              data.prq_isotonic_drinks_time_arr?.length || data.prq_eat_before_practice || 
              data.prq_eat_before_practice_items_arr?.length || data.prq_eat_before_practice_other || 
              data.prq_eat_on_breaks || data.prq_eat_on_breaks_items_arr?.length || 
              data.prq_eat_on_breaks_other) {
            
            // This is an existing quiz with data - patch all values
            this.practicequizForm.patchValue(data);
            this.patchFormArrays(data);
          }
          // Note: We're not loading data from previous quizzes anymore

          if (this.isUnderNineteen) {
              this.practicequizForm.get('prq_practice_timing').setValidators(Validators.required);
          }

          if (this.isLocked) {
              this.practicequizForm.disable();
          }
        } else {
          // ID doesn't match or no data found
          this.router.navigate(['/'], { replaceUrl: true });
          return;
        }
      } else {
        const res = await this.restService.getLast({ prq_usr_id: this.appState.user._id });
        const navTo = res[0]?._id ? `Practicequiz/${res[0]._id}` : '/';
        this.router.navigate([navTo], { replaceUrl: true });
        return;
      }
    });

    this.practicequizForm.get('prq_practice_timing').valueChanges
      .subscribe(value => {
        if (this.isLocked || this.isUnderNineteen) return;
        if (value == 'כן') {
          this.practicequizForm.get('prq_practice_timing_days').setValidators(Validators.required);
        } else {
          this.practicequizForm.get('prq_practice_timing_days').clearValidators();
          this.resetFormArray("prq_practice_timing_days");
        }
        this.practicequizForm.get('prq_practice_timing_days').updateValueAndValidity();
        // console.log(this.practicequizForm);
      }
      );

    // this.practicequizForm.get('prq_strength_practice_type_alone').valueChanges
    // .subscribe(value => {
    //   if (this.isLocked) {
    //     return;
    //   }
    //   if (value) {
    //     this.practicequizForm.get('prq_strength_practice_plan').setValidators(Validators.required);
    //   } else {
    //     this.practicequizForm.get('prq_strength_practice_plan').clearValidators();
    //   }
    //   this.practicequizForm.get('prq_strength_practice_plan').updateValueAndValidity();
    // });

    this.practicequizForm.get('prq_strength_practice_plan').valueChanges
    .subscribe(value => {
      if (this.isLocked) {
        return;
      }
      if (value === 'אחר') {
        this.practicequizForm.get('prq_strength_practice_plan_other').setValidators(Validators.required);
      } else {
        this.practicequizForm.get('prq_strength_practice_plan_other').clearValidators();
      }
      this.practicequizForm.get('prq_strength_practice_plan_other').updateValueAndValidity();
    });

    this.practicequizForm.get('prq_eat_before_practice_items_arr').valueChanges
    .subscribe(value => {
      if (this.isLocked) {
        return;
      }
      if (value?.includes('אחר')) {
        this.practicequizForm.get('prq_eat_before_practice_other').setValidators(Validators.required);
      } else {
        this.practicequizForm.get('prq_eat_before_practice_other').clearValidators();
      }
      this.practicequizForm.get('prq_eat_before_practice_other').updateValueAndValidity();
    });

    // this.practicequizForm.get('prq_morning_practice').valueChanges
    //   .subscribe(value => {
    //     if (this.isLocked) return;
    //     if (value == 'כן') {
    //       this.practicequizForm.get('prq_morning_practice_days').setValidators(Validators.required);
    //       this.practicequizForm.get('prq_earliest_practice_time').setValidators(Validators.required);
    //     } else {
    //       this.practicequizForm.get('prq_morning_practice_days').clearValidators();
    //       this.resetFormArray("prq_morning_practice_days");
    //       this.practicequizForm.get('prq_earliest_practice_time').clearValidators();
    //       this.resetField("prq_earliest_practice_time",'08:00');
    //     }
    //     this.practicequizForm.get('prq_morning_practice_days').updateValueAndValidity();
    //     this.practicequizForm.get('prq_earliest_practice_time').updateValueAndValidity();
    //     // console.log(this.practicequizForm);
    //   }
    //   );

  }

  ngOnDestroy() {
    if (this.translate.currentLang !== 'en') {
      if (this.appState.user.usr_gender === 'נקבה') {
          this.translate.use('he_F');
      } else {
          this.translate.use('he');
      }
    }
  }

  oldValuesPopup() {
    const options = {
      title: '',
      message: 'חלק מהתשובות בשאלון זה מולאו אוטומטית על פי התשובות שלך מהפעם הקודמת. במידה שיש שינוי, חשוב לעדכן את התשובות לסטטוס הנוכחי.',
      // cancelText: this.translate.instant("general.no"),
      // confirmText: this.translate.instant("general.yes"),
      confirmText: 'אישור',
    };
    this.dialogService.open(options);
  }

  conditionalValidator(predicate: Function,
    validator: Function,
    parentKeyAndValueEquals?: any, parentKeyAndValueNotEquals?: any): Function {
    return (formControl => {

      if (!formControl.parent) {
        return null;
      }

      let error = null;

      if (parentKeyAndValueEquals) {
        if (formControl.parent.get(parentKeyAndValueEquals.key).value == parentKeyAndValueEquals.value) {
          error = validator(formControl);
        }
      } else if (parentKeyAndValueNotEquals) {
        if (formControl.parent.get(parentKeyAndValueNotEquals.key).value != parentKeyAndValueNotEquals.value) {
          error = validator(formControl);
        }
      } else {
        if (predicate()) {
          error = validator(formControl);
        }
      }

      return error;
    })
  }

  // updateChkbxArray(value, isChecked, key) {
  //   const chkArray = <FormArray>this.practicequizForm.get(key);
  //   if (isChecked) {
  //     chkArray.push(new FormControl(value));
  //   } else {
  //     let idx = chkArray.controls.findIndex(x => x.value == value);
  //     chkArray.removeAt(idx);
  //   }
  //   // console.log(chkArray);
  // }

  updateChkbxArray(value: string, isChecked: boolean, key: string) {
    const chkArray = <FormArray>this.practicequizForm.get(key);
    if (!chkArray) {
      return;
    }
    const idx = chkArray.controls.findIndex(x => x.value == value);
    if (isChecked && idx === -1) {
      chkArray.push(new FormControl(value));
    }
    if (!isChecked && idx > -1) {
      chkArray.removeAt(idx);
    }
  }

  patchFormArrays(data: any) {
    this.patchGenericArray(data, ['prq_extra_practice_arr'], this, this.createPracticeItem);

    for (let i = 0; i < (data['prq_practice_timing_days'].length || 0); i++) {
      this.updateChkbxArray(data['prq_practice_timing_days'][i], true, 'prq_practice_timing_days')
    }

    // for (let i = 0; i < (data['prq_morning_practice_days'] && data['prq_morning_practice_days'].length || 0); i++) {
    //   this.updateChkbxArray(data['prq_morning_practice_days'][i], true, 'prq_morning_practice_days')
    // }
    
  }

  patchGenericArray(data, fieldArrayNames, context, createFunctionRef) {
    for (let i = 0; i < fieldArrayNames.length; i++) {
      let formArray: FormArray = this.practicequizForm.get(fieldArrayNames[i]) as FormArray;
      formArray.clear();
      for (let innerIndex = 0; innerIndex < data[fieldArrayNames[i]].length; innerIndex++) {
        let newFormElement = createFunctionRef(context);
        formArray.push(newFormElement);
      }
      formArray.patchValue(data[fieldArrayNames[i]]);
    }
  }

  resetField(fieldName, value) {
    this.practicequizForm.controls[fieldName].reset(value);
  }

  addPracticeItem(context, arrFieldName) {
    let practiceArr = context.practicequizForm.get(arrFieldName) as FormArray;
    practiceArr.push(context.createPracticeItem(context));
  }

  removePracticeItem(context, checkboxFieldName, arrFieldName, index) {
    let practiceArr = context.practicequizForm.get(arrFieldName) as FormArray;
    practiceArr.removeAt(index);
    if (practiceArr.length == 0) {

      context.practicequizForm.controls[checkboxFieldName].reset('לא');
    }
  }

  createPracticeItem(context) {
    return context.formBuilder.group({
      practice_type: ['', Validators.required],
      practice_type_other_details: ['', [context.conditionalValidator(null,
        Validators.required,
        { key: "practice_type", value: "אחר" },
        null)]],
      practice_frequency: ['', Validators.required],
      practice_length: ['60', Validators.required],
      practice_remarks: [''],
    })
  }

  practiceRadioOnClick(practiceArrayFieldName: string) {
    if (this.isLocked) return;

    if (this.practicequizForm.value[practiceArrayFieldName].length == 0) {
      this.addPracticeItem(this, practiceArrayFieldName);
    }
  }

  // resetDays() {
  //   this.resetFormArray("prq_practice_timing_days");
  // }

  async onSubmit(form: FormGroup, prefix: string) {
    this.isBusy = true;

    this.clearValuesFromUnusedFields();
    const validateRes = this.validateForm();

    if (!validateRes.valid) {
        // if (validateRes.scrollIntoNativeElement) {
        //   validateRes.scrollIntoNativeElement.nativeElement.scrollIntoView();
        // }
        this.showErrors = true;
        this.showStatus(validateRes.msg, null);
        this.isBusy = false;
        return;
    } else {
        this.showErrors = false;
    }

    this.resetField(`${prefix}_form_is_real`, true);

    // this.clearAllIds();

    if (form.value._id) {
        const res = await this.restService.patch(form.value._id, form.value).catch(e => { console.error(e); });
        if (res && res._id && res[`${prefix}_usr_id`]) {
            // this.buildAssetsArray(form, `${prefix}_assets_arr`, res);
            form.patchValue(res);
            this.patchFormArrays(res);
            
            this.showStatus(this.translate.instant('general.savedSuccessfully'), null);
            if (this.appState.user.usr_type === 'player'){
              setTimeout(()=>{
                this.router.navigate([`Userprogress`]);
                this.isBusy = false;
              }, 2000);
              return;
            }
        } else {
            this.showStatus('אירעה שגיאה', null);
        }
    }
    
    this.isBusy = false;
  }

  validateForm() {
    const value = this.practicequizForm.value;
    this.morningPracticeDaysError = value.prq_morning_practice == 'כן' && !(value.prq_morning_practice_time_sunday || value.prq_morning_practice_time_monday || value.prq_morning_practice_time_tuesday || value.prq_morning_practice_time_wednesday || value.prq_morning_practice_time_thursday || value.prq_morning_practice_time_friday || value.prq_morning_practice_time_saturday);

    this.strengthPracticeTypeError = value.prq_strength_practice == 'כן' && (!(value.prq_strength_practice_type_group || value.prq_strength_practice_type_alone) || !value.prq_strength_practice_plan);
    
    this.isotonicTimesError = value.prq_isotonic_drinks !== 'לא' && (!value.prq_isotonic_drinks_time_arr || !value.prq_isotonic_drinks_time_arr.length);
    
    this.beforePracticeError = value.prq_eat_before_practice !== 'אף פעם' && (!value.prq_eat_before_practice_items_arr || !value.prq_eat_before_practice_items_arr.length);
    
    this.onBreaksError = value.prq_eat_on_breaks !== 'לא' && (!value.prq_eat_on_breaks_items_arr || !value.prq_eat_on_breaks_items_arr.length);

    // this.isotonicTimesError = value.prq_isotonic_drinks == 'כן' && (!value.prq_isotonic_drinks_time_arr || !value.prq_isotonic_drinks_time_arr.length);
    
    // this.beforePracticeError = (value.prq_eat_before_practice === 'תמיד' || value.prq_eat_before_practice === 'בדרך כלל' || value.prq_eat_before_practice === 'לעיתים רחוקות') && (!value.prq_eat_before_practice_items_arr || !value.prq_eat_before_practice_items_arr.length);
    
    // this.onBreaksError = value.prq_eat_on_breaks === 'כן' && (!value.prq_eat_on_breaks_items_arr || !value.prq_eat_on_breaks_items_arr.length);

    if (!this.practicequizForm.valid || this.morningPracticeDaysError || this.strengthPracticeTypeError || this.isotonicTimesError || this.beforePracticeError || this.onBreaksError) {
      return {
        valid: false,
        msg: "נא לתקן את השדות האדומים"
      }      
    }

    return {
      valid: true,
      msg: "ולידציה עברה בהצלחה"
    }

  }

  /*
  Go through all the fields in the form, clean unused ones
  */
  clearValuesFromUnusedFields() {

    if (this.practicequizForm.value.prq_extra_practice == 'לא') {
      this.resetFormArray("prq_extra_practice_arr");
    }
    this.clearPracticeArrayDetailsFieldAccordingToStatus("prq_extra_practice_arr");

    if (this.practicequizForm.value.prq_practice_timing == 'לא') {
      this.resetFormArray("prq_practice_timing_days");
    }

    if (this.practicequizForm.value.prq_morning_practice == 'לא') {
      // this.resetFormArray("prq_morning_practice_days");
      // this.resetField("prq_earliest_practice_time",'');
      this.resetField("prq_morning_practice_time_sunday",'');
      this.resetField("prq_morning_practice_time_monday",'');
      this.resetField("prq_morning_practice_time_tuesday",'');
      this.resetField("prq_morning_practice_time_wednesday",'');
      this.resetField("prq_morning_practice_time_thursday",'');
      this.resetField("prq_morning_practice_time_friday",'');
      this.resetField("prq_morning_practice_time_saturday",'');
    }
    
    if (this.practicequizForm.value.prq_strength_practice == 'לא') {
      this.resetField("prq_strength_practice_type_group", null);
      this.resetField("prq_strength_practice_type_alone", null);
    }

    // if (!this.practicequizForm.value.prq_strength_practice_type_alone) {
    //   this.resetField("prq_strength_practice_plan",'');
    //   // this.resetField(prq_strength_practice_plan_other",'');
    // }

    if (this.practicequizForm.value.prq_strength_practice_plan !== 'אחר') {
      this.resetField("prq_strength_practice_plan_other",'');
    }

    if (this.practicequizForm.value.prq_isotonic_drinks == 'לא') {
      this.resetField("prq_isotonic_drinks_time_arr", null);
    }

    if (this.practicequizForm.value.prq_eat_before_practice === 'אף פעם') {
      this.resetField("prq_eat_before_practice_items_arr", null);
      this.resetField("prq_eat_before_practice_other", '');
    }

    if (this.practicequizForm.value.prq_eat_on_breaks === 'לא') {
      this.resetField("prq_eat_on_breaks_items_arr", null);
      this.resetField("prq_eat_on_breaks_other", '');
    }

    // if (this.practicequizForm.value.prq_eat_before_practice !== 'תמיד' && this.practicequizForm.value.prq_eat_before_practice !== 'בדרך כלל' && this.practicequizForm.value.prq_eat_before_practice !== 'לעיתים רחוקות') {
    //   this.resetField("prq_eat_before_practice_items_arr", null);
    //   this.resetField("prq_eat_before_practice_other", '');
    // }

    // if (this.practicequizForm.value.prq_eat_on_breaks !== 'כן') {
    //   this.resetField("prq_eat_on_breaks_items_arr", null);
    //   this.resetField("prq_eat_on_breaks_other", '');
    // }
  }

  clearPracticeArrayDetailsFieldAccordingToStatus(arrayFieldName: string) {
    let formArray = this.practicequizForm.get(arrayFieldName) as FormArray;
    let length: number = formArray.length;
    for (let i = 0; i < length; i++) {
      if (formArray.controls[i].value.practice_type != 'אחר') {
        formArray.controls[i].get("practice_type_other_details").reset('');
      }
    }
  }

  resetFormArray(arrayFieldName) {
    let formArray = this.practicequizForm.get(arrayFieldName) as FormArray;
    let length: number = formArray.length;
    for (let i = 0; i < length; i++) {
      formArray.removeAt(0);
    }
  }

  showStatus(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getObjKeys(obj) {
    return Object.keys(obj) || [];

  }
  
  checkedFn(event, day) {
    const formControl = this.practicequizForm.get('prq_morning_practice_time_' + day);

    if (event.checked) {
        // formControl.enable();
        formControl.patchValue('07:00');
        formControl.setValidators(Validators.required);
    } else {
      // formControl.disable();
      formControl.clearValidators();
      formControl.reset('');
    }
    formControl.updateValueAndValidity();
  }

  checkedFnArray(value, isChecked, key) {
    const control = this.practicequizForm.get(key);
    let val = control.value ? [...control.value] : [];
    if (isChecked) {
      if (!val.includes(value)) {
        val.push(value);
      }
    } else {
      let idx = val.findIndex(x => x === value);
      if (idx > -1) {
        val.splice(idx, 1);
      }
    }
    control.patchValue(val);
    control.updateValueAndValidity();
  }

}

