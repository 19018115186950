<div class="antro-form">

    <mat-expansion-panel disabled expanded>
        <mat-expansion-panel-header>
            <!-- <mat-panel-title class="font-18 font-bold">
                נתונים אנתרופומטריים
            </mat-panel-title>
            <mat-panel-description>
                גובה, משקל, BMI
            </mat-panel-description> -->
            <mat-panel-title class="font-16 font-bold">
                <h4 class="m-auto">מדדים</h4>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="row mb125">
            <button class="plus-button" mat-raised-button (click)="newAntroQuizGuard()">
                <!-- {{'nuttest.newquiz' | translate}} -->
                הוספת מדידה חדשה
                <mat-icon>add</mat-icon>
            </button>
        </div>

        <div *ngIf="oldValues.length > 0" class="antropometrics content text-center">
            <div class="row mb50 text-center">
                <div class="w30" *ngIf="selectedA">
                    <!-- <button type="button" [routerLink]="['/Nuttestquiz/', selectedIdA]" class="plus-button" mat-stroked-button>עבור לטופס</button> -->
                    <a target="quizwindow" [routerLink]="['/Nuttestquiz/', selectedIdA]" class="plus-button" mat-stroked-button>עבור לטופס</a>
                </div>
                <div class="w30" *ngIf="selectedA && selectedB">
                    <div *ngIf="chosenLastNtq" class="ul-text">מדידה להשוואה</div>
                    <div *ngIf="chosenLastNtq">
                        <span class="font-bold font-12 text-danger">
                            {{ chosenLastNtq.ntq_test_date | date:'dd/MM/yyyy' }}
                            {{ chosenLastNtq.is_by_user ? ' (מדידת משתמש) ' : '' }}
                        </span>
                        <button mat-icon-button (click)="setChosenLastNtq($event, null)">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="w30" *ngIf="selectedB">
                    <!-- <button type="button" [routerLink]="['/Nuttestquiz/', selectedIdB]" class="plus-button" mat-stroked-button>עבור לטופס</button> -->
                    <a target="quizwindow" [routerLink]="['/Nuttestquiz/', selectedIdB]" class="plus-button" mat-stroked-button>עבור לטופס</a>
                </div>
            </div>
            <div class="row">
                <mat-form-field *ngIf="oldValues.length > 0" class="w30">
                    <mat-select [(ngModel)]="selectedIdA" (ngModelChange)="selectDateA($event)">
                        <ng-container *ngFor="let val of oldValues; let i = index">
                            <mat-option *ngIf="selectedIdB != val._id" [value]="val._id">
                                <!-- {{ val['ntq_test_date'] ? (val['ntq_test_date'] | date:'dd/MM/yyyy') : 'ללא תאריך' }} -->
                                <span *ngIf="val['ntq_test_date']">
                                    {{ val['ntq_test_date'] | date:'dd/MM/yyyy' }}
                                    {{ val['is_by_user'] ? ' (מדידת משתמש) ' : '' }}
                                </span>
                                <span *ngIf="!val['ntq_test_date']">
                                    ללא תאריך - נוצר ב
                                    {{ val['createdAt'] | date:'dd/MM/yyyy' }}
                                </span>
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

                <button *ngIf="oldValues.length > 1" class="w30" mat-stroked-button color="primary" (click)="fetchOldValues(true)">אפס לשתי המדידות האחרונות</button>

                <mat-form-field *ngIf="oldValues.length > 1" class="w30">
                    <!-- <button type="button" *ngIf="selectedB && selectedIdB" [disabled]="!selectedB.ntq_test_date || (chosenLastNtq && chosenLastNtq._id && chosenLastNtq._id === selectedIdB)" matPrefix (click)="setChosenLastNtq($event, selectedB)" mat-stroked-button>בחר</button> -->
                    <mat-checkbox matPrefix *ngIf="selectedB && selectedIdB" [disabled]="!selectedB.ntq_test_date || (chosenLastNtq && chosenLastNtq._id && chosenLastNtq._id === selectedIdB)" [checked]="chosenLastNtq && chosenLastNtq._id && chosenLastNtq._id === selectedIdB" (click)="setChosenLastNtq($event, selectedB)"></mat-checkbox>
                    <mat-select [(ngModel)]="selectedIdB" (ngModelChange)="selectDateB($event)">
                        <ng-container *ngFor="let val of oldValues; let i = index">
                            <mat-option *ngIf="selectedIdA != val._id" [value]="val._id">
                                <!-- {{ val['ntq_test_date'] ? (val['ntq_test_date'] | date:'dd/MM/yyyy') : 'ללא תאריך' }} -->
                                <span *ngIf="val['ntq_test_date']">
                                    {{ val['ntq_test_date'] | date:'dd/MM/yyyy' }}
                                    {{ val['is_by_user'] ? ' (מדידת משתמש) ' : '' }}
                                </span>
                                <span *ngIf="!val['ntq_test_date']">
                                    ללא תאריך - נוצר ב
                                    {{ val['createdAt'] | date:'dd/MM/yyyy' }}
                                </span>
                            </mat-option>
                        </ng-container>
                    </mat-select>
                    <!-- <div matSuffix *ngIf="chosenLastNtq">
                        <span class="text-danger font-12">
                            {{ chosenLastNtq.ntq_test_date | date:'dd/MM' }}
                            {{ chosenLastNtq.is_by_user ? ' (מדידת משתמש) ' : '' }}
                        </span>
                        <button mat-icon-button (click)="setChosenLastNtq($event, null)">
                          <mat-icon>close</mat-icon>
                        </button>
                    </div> -->
                </mat-form-field>
            </div>


            <div class="row" *ngIf="selectedA?.savedAt || selectedB?.savedAt">
                <div class="w30" *ngIf="selectedA">
                    <div class="font-bold font-12 text-info" *ngIf="selectedA.savedAt">
                        מולא ע"י המשתמש
                        {{ selectedA.savedAt | date:'dd/MM/yyyy' }}
                    </div>
                </div>
                <div class="w30 middle" *ngIf="selectedA && selectedB">

                </div>
                <div class="w30" *ngIf="selectedB">
                    <div class="font-bold font-12 text-info" *ngIf="selectedB.savedAt">
                        מולא ע"י המשתמש
                        {{ selectedB.savedAt | date:'dd/MM/yyyy' }}
                    </div>
                </div>
            </div>


            <div class="row custom-row">
                <div style="width: auto;" class="w30" *ngIf="selectedA">
                    <mat-form-field class="w100">
                        <mat-select [(value)]="selectedA.ntq_dev_status" (selectionChange)="onChange($event, 'notesFormA', 'ntq_dev_status')" placeholder="הזן סטטוס התפתחות">
                                <mat-option value="ללא התייחסות">ללא התייחסות</mat-option>
                                <mat-option value="טרום התפתחות">טרום התפתחות</mat-option>
                                <mat-option value="סיום התפתחות">סיום התפתחות</mat-option>
                                <mat-option value="בגרות">בגרות</mat-option>

                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="w100">
                        <mat-select [(value)]="selectedA.ntq_height_trend" (selectionChange)="onChange($event, 'notesFormA', 'ntq_height_trend')" placeholder="הזן מגמת גובה">
                          <mat-option value="ללא התייחסות">ללא התייחסות</mat-option>
                          <mat-option value="שמירה">שמירה</mat-option>
                          <mat-option value="ירידה קלה">ירידה קלה</mat-option>
                          <mat-option value="ירידה משמעותית">ירידה משמעותית</mat-option>
                          <mat-option value="עליה קלה">עליה קלה</mat-option>
                          <mat-option value="עליה משמעותית">עליה משמעותית</mat-option>
                          <mat-option value="מדידה ראשונה">מדידה ראשונה</mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>
                <div class="w30 middle">
                </div>
                <div class="w30" *ngIf="selectedB">
                    <div style="width: auto;" class="w30" *ngIf="selectedB">
                        <mat-form-field class="w100">
                            <mat-select [(value)]="selectedB.ntq_dev_status" (selectionChange)="onChange($event, 'notesFormB', 'ntq_dev_status')" placeholder="הזן סטטוס התפתחותי">
                                <mat-option value="ללא התייחסות">ללא התייחסות</mat-option>
                                <mat-option value="טרום התפתחות">טרום התפתחות</mat-option>
                                <mat-option value="סיום התפתחות">סיום התפתחות</mat-option>
                                <mat-option value="בגרות">בגרות</mat-option>
                              </mat-select>
                          </mat-form-field>
                          <mat-form-field class="w100">
                            <mat-select [(value)]="selectedB.ntq_height_trend" (selectionChange)="onChange($event, 'notesFormB', 'ntq_height_trend')" placeholder="הזן מגמת גובה">
                              <mat-option value="ללא התייחסות">ללא התייחסות</mat-option>
                              <mat-option value="שמירה">שמירה</mat-option>
                              <mat-option value="ירידה קלה">ירידה קלה</mat-option>
                              <mat-option value="ירידה משמעותית">ירידה משמעותית</mat-option>
                              <mat-option value="עליה קלה">עליה קלה</mat-option>
                              <mat-option value="עליה משמעותית">עליה משמעותית</mat-option>
                              <mat-option value="מדידה ראשונה">מדידה ראשונה</mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
                </div>
            </div>

            <h3>גובה</h3>
            <div class="row custom-row">
                <div class="w30" *ngIf="selectedA">
                    <p class="font-bold" *ngIf="selectedA['ntq_height']">
                        {{ selectedA['ntq_height'] }} ס"מ
                    </p>
                    <p *ngIf="selectedA['ntq_height_p']">
                        אחוזון: <span class="font-bold">{{ selectedA['ntq_height_p']}}</span>
                    </p>
                    <ng-container *ngIf="notesFormA.value._id" [ngTemplateOutlet]="antroRadiosTemplate" [ngTemplateOutletContext]="{
                        selectedForm: notesFormA,
                        fieldName: 'notes_nut_height'
                    }">
                    </ng-container>
                    <!-- <notes *ngIf="notesFormA.value._id" [notesArr]="notesList['notes_nut_height']" [selectedForm]="notesFormA"
                        [fieldName]="'notes_nut_height'"></notes> -->
                </div>
                <div class="w30 middle" *ngIf="selectedA && selectedB">
                    <div *ngIf="selectedA['ntq_height'] && selectedB['ntq_height']">
                        הפרש גובה (ס&quot;מ): <div class="font-bold dl dir-ltr">{{calcDiff(selectedA['ntq_height'], selectedB['ntq_height'])}}</div>
                    </div>
                    <div *ngIf="selectedA['ntq_height_p'] && selectedB['ntq_height_p']">
                        הפרש אחוזון: <div class="font-bold dl dir-ltr">{{calcDiff(selectedA['ntq_height_p'], selectedB['ntq_height_p'])}}%</div>
                    </div>
                    <div class="dir-ltr" *ngIf="selectedA['ntq_height_p'] && (oldValues | percentageFlag : 'ntq_height_p' : selectedA['ntq_height_p']).num">
                        <div class="text-danger font-bold dl dir-ltr">
                            {{ (oldValues | percentageFlag : 'ntq_height_p' : selectedA['ntq_height_p']).num }}%
                        </div>
                        &nbsp;
                        <div class="font-bold font-12 dl">(
                            {{ (oldValues | percentageFlag : 'ntq_height_p' : selectedA['ntq_height_p']).date }}
                            )</div>
                    </div>
                </div>
                <div class="w30" *ngIf="selectedB">
                    <p class="font-bold" *ngIf="selectedB['ntq_height']">
                        {{ selectedB['ntq_height'] }} ס"מ
                    </p>
                    <p *ngIf="selectedB['ntq_height_p']">
                        אחוזון: <span class="font-bold">{{ selectedB['ntq_height_p']}}</span>
                    </p>
                    <ng-container *ngIf="notesFormB.value._id" [ngTemplateOutlet]="antroRadiosTemplate" [ngTemplateOutletContext]="{
                        selectedForm: notesFormB,
                        fieldName: 'notes_nut_height'
                    }">
                    </ng-container>
                    <!-- <notes *ngIf="notesFormB.value._id" [notesArr]="notesList['notes_nut_height']" [selectedForm]="notesFormB"
                        [fieldName]="'notes_nut_height'"></notes> -->
                </div>
            </div>

            <h3>משקל</h3>
            <div class="row custom-row">
                <div class="w30" *ngIf="selectedA">
                    <p class="font-bold" *ngIf="selectedA['ntq_weight']">
                        {{ selectedA['ntq_weight'] }} ק"ג
                    </p>
                    <p *ngIf="selectedA['ntq_weight_p']">
                        אחוזון: <span class="font-bold">{{ selectedA['ntq_weight_p']}}</span>
                    </p>
                    <ng-container *ngIf="notesFormA.value._id" [ngTemplateOutlet]="antroRadiosTemplate" [ngTemplateOutletContext]="{
                        selectedForm: notesFormA,
                        fieldName: 'notes_nut_weight'
                    }">
                    </ng-container>
                    <!-- <notes *ngIf="notesFormA.value._id" [notesArr]="notesList['notes_nut_weight']" [selectedForm]="notesFormA"
                        [fieldName]="'notes_nut_weight'"></notes> -->
                </div>
                <div class="w30 middle" *ngIf="selectedA && selectedB">
                    <div *ngIf="selectedA['ntq_weight'] && selectedB['ntq_weight']">
                        הפרש משקל (ק&quot;ג): <div class="font-bold dl dir-ltr">{{calcDiff(selectedA['ntq_weight'], selectedB['ntq_weight'])}}</div>
                    </div>
                    <div *ngIf="selectedA['ntq_weight_p'] && selectedB['ntq_weight_p']">
                        הפרש אחוזון: <div class="font-bold dl dir-ltr">{{calcDiff(selectedA['ntq_weight_p'], selectedB['ntq_weight_p'])}}%</div>
                    </div>
                    <div class="dir-ltr" *ngIf="selectedA['ntq_weight_p'] && (oldValues | percentageFlag : 'ntq_weight_p' : selectedA['ntq_weight_p']).num">
                        <div class="text-danger font-bold dl dir-ltr">
                            {{ (oldValues | percentageFlag : 'ntq_weight_p' : selectedA['ntq_weight_p']).num }}%
                        </div>
                        &nbsp;
                        <div class="font-bold font-12 dl">(
                            {{ (oldValues | percentageFlag : 'ntq_weight_p' : selectedA['ntq_weight_p']).date }}
                            )</div>
                    </div>
                </div>
                <div class="w30" *ngIf="selectedB">
                    <p class="font-bold" *ngIf="selectedB['ntq_weight']">
                        {{ selectedB['ntq_weight'] }} ק"ג
                    </p>
                    <p *ngIf="selectedB['ntq_weight_p']">
                        אחוזון: <span class="font-bold">{{ selectedB['ntq_weight_p']}}</span>
                    </p>
                    <ng-container *ngIf="notesFormB.value._id" [ngTemplateOutlet]="antroRadiosTemplate" [ngTemplateOutletContext]="{
                        selectedForm: notesFormB,
                        fieldName: 'notes_nut_weight'
                    }">
                    </ng-container>
                    <!-- <notes *ngIf="notesFormB.value._id" [notesArr]="notesList['notes_nut_weight']" [selectedForm]="notesFormB"
                        [fieldName]="'notes_nut_weight'"></notes> -->
                </div>
            </div>
            <div class="row custom-row">
                <div style="width: auto;" class="w30" *ngIf="selectedA">
                    <mat-form-field class="w100">
                        <mat-select [(value)]="selectedA.ntq_bmi_status"  (selectionChange)="onChange($event, 'notesFormA', 'ntq_bmi_status')" placeholder="הזן סטטוס BMI">
                            <mat-option value="ללא התייחסות">ללא התייחסות</mat-option>
                            <mat-option value="נמוך מאוד">נמוך מאוד</mat-option>
                            <mat-option value="מעט נמוך">מעט נמוך</mat-option>
                            <mat-option value="תקין">תקין</mat-option>
                            <mat-option value="מעט גבוה">מעט גבוה</mat-option>
                            <mat-option value="גבוה">גבוה</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w100">
                        <mat-select [(value)]="selectedA.ntq_bmi_trend" (selectionChange)="onChange($event, 'notesFormA', 'ntq_bmi_trend')"  placeholder="הזן מגמת BMI">
                            <mat-option value="ללא התייחסות">ללא התייחסות</mat-option>
                            <mat-option value="שמירה">שמירה</mat-option>
                            <mat-option value="ירידה קלה">ירידה קלה</mat-option>
                            <mat-option value="ירידה משמעותית">ירידה משמעותית</mat-option>
                            <mat-option value="עליה קלה">עליה קלה</mat-option>
                            <mat-option value="עליה משמעותית">עליה משמעותית</mat-option>
                            <mat-option value="מדידה ראשונה">מדידה ראשונה</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                
                <div class="w30 middle"></div>
                
                <div class="w30" *ngIf="selectedB">
                    <mat-form-field class="w100">
                        <mat-select [(value)]="selectedA.ntq_bmi_status" (selectionChange)="onChange($event, 'notesFormB', 'ntq_bmi_status')" placeholder="הזן סטטוס BMI">
                            <mat-option value="ללא התייחסות">ללא התייחסות</mat-option>
                            <mat-option value="נמוך מאוד">נמוך מאוד</mat-option>
                            <mat-option value="מעט נמוך">מעט נמוך</mat-option>
                            <mat-option value="תקין">תקין</mat-option>
                            <mat-option value="מעט גבוה">מעט גבוה</mat-option>
                            <mat-option value="גבוה">גבוה</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w100">
                        <mat-select [(value)]="selectedA.ntq_bmi_trend" (selectionChange)="onChange($event, 'notesFormB', 'ntq_bmi_trend')"  placeholder="הזן מגמת BMI">
                            <mat-option value="ללא התייחסות">ללא התייחסות</mat-option>
                            <mat-option value="שמירה">שמירה</mat-option>
                            <mat-option value="ירידה קלה">ירידה קלה</mat-option>
                            <mat-option value="ירידה משמעותית">ירידה משמעותית</mat-option>
                            <mat-option value="עליה קלה">עליה קלה</mat-option>
                            <mat-option value="עליה משמעותית">עליה משמעותית</mat-option>
                            <mat-option value="מדידה ראשונה">מדידה ראשונה</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            
            

            <h3>BMI</h3>
            <div class="row custom-row">
                <div class="w30" *ngIf="selectedA">
                    <!-- <p class="font-bold" *ngIf="selectedA['ntq_weight'] && selectedA['ntq_height']">
                        {{ calcBmi(selectedA['ntq_weight'], selectedA['ntq_height']) }}
                    </p> -->
                    <p class="font-bold" *ngIf="selectedA['ntq_bmi']">
                        {{ selectedA['ntq_bmi']}}
                    </p>
                    <p *ngIf="selectedA['ntq_bmi_p']">
                        אחוזון: <span class="font-bold">{{ selectedA['ntq_bmi_p']}}</span>
                    </p>
                    <ng-container *ngIf="notesFormA.value._id" [ngTemplateOutlet]="antroRadiosTemplate" [ngTemplateOutletContext]="{
                        selectedForm: notesFormA,
                        fieldName: 'notes_nut_bmi'
                    }">
                    </ng-container>
                    <!-- <notes *ngIf="notesFormA.value._id" [notesArr]="notesList['notes_nut_bmi']" [selectedForm]="notesFormA"
                        [fieldName]="'notes_nut_bmi'"></notes> -->
                </div>
                <div class="w30 middle" *ngIf="selectedA && selectedB">
                    <!-- <div *ngIf="selectedA['ntq_weight'] && selectedB['ntq_weight'] && selectedA['ntq_height'] && selectedB['ntq_height']">
                        <span style="display: inline-block;">הפרש BMI</span>:
                        <div class="font-bold dl dir-ltr">{{calcDiff(calcBmi(selectedA['ntq_weight'], selectedA['ntq_height']), calcBmi(selectedB['ntq_weight'], selectedB['ntq_height']))}}</div>
                    </div> -->
                    <div *ngIf="selectedA['ntq_bmi'] && selectedB['ntq_bmi']">
                        <span style="display: inline-block;">הפרש BMI</span>:
                        <div class="font-bold dl dir-ltr">{{calcDiff(selectedA['ntq_bmi'], selectedB['ntq_bmi'])}}</div>
                    </div>
                    <div *ngIf="selectedA['ntq_bmi_p'] && selectedB['ntq_bmi_p']">
                        הפרש אחוזון: <div class="font-bold dl dir-ltr">{{calcDiff(selectedA['ntq_bmi_p'], selectedB['ntq_bmi_p'])}}%</div>
                    </div>
                    <div class="dir-ltr" *ngIf="selectedA['ntq_bmi_p'] && (oldValues | percentageFlag : 'ntq_bmi_p' : selectedA['ntq_bmi_p']).num">
                        <div class="text-danger font-bold dl dir-ltr">
                            {{ (oldValues | percentageFlag : 'ntq_bmi_p' : selectedA['ntq_bmi_p']).num }}%
                        </div>
                        &nbsp;
                        <div class="font-bold font-12 dl">(
                            {{ (oldValues | percentageFlag : 'ntq_bmi_p' : selectedA['ntq_bmi_p']).date }}
                            )</div>
                    </div>
                </div>
                <div class="w30" *ngIf="selectedB">
                    <!-- <p class="font-bold" *ngIf="selectedB['ntq_weight'] && selectedB['ntq_height']">
                        {{ calcBmi(selectedB['ntq_weight'], selectedB['ntq_height']) }}
                    </p> -->
                    <p class="font-bold" *ngIf="selectedB['ntq_bmi']">
                        {{ selectedB['ntq_bmi']}}
                    </p>
                    <p *ngIf="selectedB['ntq_bmi_p']">
                        אחוזון: <span class="font-bold">{{ selectedB['ntq_bmi_p']}}</span>
                    </p>
                    <ng-container *ngIf="notesFormB.value._id" [ngTemplateOutlet]="antroRadiosTemplate" [ngTemplateOutletContext]="{
                        selectedForm: notesFormB,
                        fieldName: 'notes_nut_bmi'
                    }">
                    </ng-container>
                    <!-- <notes *ngIf="notesFormB.value._id" [notesArr]="notesList['notes_nut_bmi']" [selectedForm]="notesFormB"
                        [fieldName]="'notes_nut_bmi'"></notes> -->
                </div>
            </div>


            <div class="row custom-row">
                <div style="width: auto;" class="w30" *ngIf="selectedA">
                    <mat-form-field class="w100">
                        <mat-select [(value)]="selectedA.ntq_fat_per_status" (selectionChange)="onChange($event, 'notesFormA', 'ntq_fat_per_status')" placeholder="הזן סטטוס אחוז שומן">
                            <mat-option value="ללא התייחסות">ללא התייחסות</mat-option>
                            <mat-option value="נמוך מאוד">נמוך מאוד</mat-option>
                            <mat-option value="נמוך תקין">נמוך תקין</mat-option>
                            <mat-option value="ממוצע (התנסחות עדינה)">ממוצע (התנסחות עדינה)</mat-option>
                            <mat-option value="מעט גבוה עבור ספורטאי">מעט גבוה עבור ספורטאי</mat-option>
                            <mat-option value="גבוה עבור ספורטאי">גבוה עבור ספורטאי</mat-option>
                            <mat-option value="בגבול העליון של הנורמה">בגבול העליון של הנורמה</mat-option>
                            <mat-option value="גבוה בריאותית">גבוה בריאותית</mat-option>
                          
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="w100">
                        <mat-select [(value)]="selectedA.ntq_fat_per_trend" (selectionChange)="onChange($event, 'notesFormA', 'ntq_fat_per_trend')" placeholder="הזן מגמת אחוז שומן">
                          <mat-option value="ללא התייחסות">ללא התייחסות</mat-option>
                          <mat-option value="שמירה">שמירה</mat-option>
                          <mat-option value="ירידה קלה">ירידה קלה</mat-option>
                          <mat-option value="ירידה משמעותית">ירידה משמעותית</mat-option>
                          <mat-option value="עליה קלה">עליה קלה</mat-option>
                          <mat-option value="עליה משמעותית">עליה משמעותית</mat-option>
                          <mat-option value="מדידה ראשונה">מדידה ראשונה</mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>
                <div class="w30 middle">
                </div>
                <div class="w30" *ngIf="selectedB">
                    <div style="width: auto;" class="w30" *ngIf="selectedB">
                        <mat-form-field class="w100">
                            <mat-select [(value)]="selectedB.ntq_fat_per_status" (selectionChange)="onChange($event, 'notesFormB', 'ntq_fat_per_status')" placeholder="הזן סטטוס אחוז שומן">
                              <mat-option value="ללא התייחסות">ללא התייחסות</mat-option>
                              <mat-option value="נמוך מאוד">נמוך מאוד</mat-option>
                              <mat-option value="מעט נמוך">מעט נמוך</mat-option>
                              <mat-option value="תקין">תקין</mat-option>
                              <mat-option value="מעט גבוה">מעט גבוה</mat-option>
                              <mat-option value="גבוה">גבוה</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field class="w100">
                            <mat-select [(value)]="selectedB.ntq_fat_per_trend" (selectionChange)="onChange($event, 'notesFormB', 'ntq_fat_per_trend')" placeholder="הזן מגמת אחוז שומן">
                              <mat-option value="ללא התייחסות">ללא התייחסות</mat-option>
                              <mat-option value="שמירה">שמירה</mat-option>
                              <mat-option value="ירידה קלה">ירידה קלה</mat-option>
                              <mat-option value="ירידה משמעותית">ירידה משמעותית</mat-option>
                              <mat-option value="עליה קלה">עליה קלה</mat-option>
                              <mat-option value="עליה משמעותית">עליה משמעותית</mat-option>
                              <mat-option value="מדידה ראשונה">מדידה ראשונה</mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
                </div>
            </div>

            <h3>אחוז שומן</h3>
            <div class="row custom-row">
                <div class="w30" *ngIf="selectedA">
                    <p *ngIf="selectedA['ntq_fat_percent_num']">
                        אחוז שומן (הזנה ידנית): <span class="font-bold">{{ selectedA['ntq_fat_percent_num'] }}</span>
                    </p>
                    <p *ngIf="selectedA['ntq_fat_sf']">
                        סכום קפלי עור: <span class="font-bold">{{ selectedA['ntq_fat_sf'] }}</span>
                    </p>
                    <p *ngIf="selectedA['ntq_fat_default']">
                        אחוז שומן לפי 20-30: <span class="font-bold">{{ selectedA['ntq_fat_default'] }}</span>
                    </p>
                    <div style="display: flex;" *ngIf="selectedA['ntq_fat_age']">
                        <mat-checkbox [formControl]="notesFormA.get('ntq_show_fat_per')" (change)="emitShowFatPercentage()"></mat-checkbox>
                        <p *ngIf="selectedA['ntq_fat_age']">
                            אחוז שומן לפי גיל: <span class="font-bold">{{ selectedA['ntq_fat_age'] }}</span>
                        </p>
                    </div>
                    <p *ngIf="selectedA['ntq_fat_mass']">
                        מסת שומן<span *ngIf="selectedA['ntq_fat_percent'] == 'קליפר'"> ({{ selectedA['ntq_fat_caliper_status'] }})</span>: <span class="font-bold">{{ selectedA['ntq_fat_mass'] }}</span>
                    </p>
                    <p *ngIf="selectedA['ntq_skinny_mass']">
                        מסה רזה<span *ngIf="selectedA['ntq_fat_percent'] == 'קליפר'"> ({{ selectedA['ntq_fat_caliper_status'] }})</span>: <span class="font-bold">{{selectedA['ntq_skinny_mass'] }}</span>
                    </p>
                    <ng-container *ngIf="notesFormA.value._id && selectedA['ntq_fat_percent'] != 'ללא אחוז שומן'" [ngTemplateOutlet]="antroRadiosTemplate" [ngTemplateOutletContext]="{
                        selectedForm: notesFormA,
                        fieldName: 'notes_nut_fat'
                    }">
                    </ng-container>
                    <!-- <notes *ngIf="notesFormA.value._id" [notesArr]="notesList['notes_nut_fat']" [selectedForm]="notesFormA"
                        [fieldName]="'notes_nut_fat'"></notes> -->
                </div>
                <div class="w30 middle" *ngIf="selectedA && selectedB">
                    <div *ngIf="selectedA['ntq_fat_percent_num'] && selectedB['ntq_fat_percent_num']">
                        הפרש אחוז שומן (הזנה ידנית):
                        <div class="font-bold dl dir-ltr">{{calcDiff(selectedA['ntq_fat_percent_num'], selectedB['ntq_fat_percent_num'])}}%</div>
                    </div>
                    <div *ngIf="selectedA['ntq_fat_sf'] && selectedB['ntq_fat_sf']">
                        <br>
                    </div>
                    <div *ngIf="selectedA['ntq_fat_default'] && selectedB['ntq_fat_default']">
                        הפרש אחוז שומן (20-30):
                        <div class="font-bold dl dir-ltr">{{calcDiff(selectedA['ntq_fat_default'], selectedB['ntq_fat_default'])}}%</div>
                    </div>
                    <div *ngIf="selectedA['ntq_fat_age'] && selectedB['ntq_fat_age']">
                        הפרש אחוז שומן לפי גיל: <div class="font-bold dl dir-ltr">{{calcDiff(selectedA['ntq_fat_age'], selectedB['ntq_fat_age'])}}%</div>
                    </div>
                    <div *ngIf="selectedA['ntq_fat_mass'] && selectedB['ntq_fat_mass']">
                        הפרש מסת שומן: <div class="font-bold dl dir-ltr">{{calcDiff(selectedA['ntq_fat_mass'], selectedB['ntq_fat_mass'])}}</div>
                    </div>
                    <div *ngIf="selectedA['ntq_skinny_mass'] && selectedB['ntq_skinny_mass']">
                        הפרש מסה רזה: <div class="font-bold dl dir-ltr">{{calcDiff(selectedA['ntq_skinny_mass'], selectedB['ntq_skinny_mass'])}}</div>
                    </div>
                </div>
                <div class="w30" *ngIf="selectedB">
                    <p *ngIf="selectedB['ntq_fat_percent_num']">
                        אחוז שומן (הזנה ידנית): <span class="font-bold">{{ selectedB['ntq_fat_percent_num'] }}</span>
                    </p>
                    <p *ngIf="selectedB['ntq_fat_sf']">
                        סכום קפלי עור: <span class="font-bold">{{ selectedB['ntq_fat_sf'] }}</span>
                    </p>
                    <p *ngIf="selectedB['ntq_fat_default']">
                        אחוז שומן לפי 20-30: <span class="font-bold">{{ selectedB['ntq_fat_default'] }}</span>
                    </p>
                    <div style="display: flex;" *ngIf="selectedB['ntq_fat_age']">
                        <mat-checkbox [formControl]="notesFormB.get('ntq_show_fat_per')" (change)="emitShowFatPercentage()"></mat-checkbox>
                        <p *ngIf="selectedB['ntq_fat_age']"> אחוז שומן לפי גיל: <span class="font-bold">{{ selectedB['ntq_fat_age'] }}</span>
                    </p>
                </div>
                    <p *ngIf="selectedB['ntq_fat_mass']">
                        מסת שומן<span *ngIf="selectedB['ntq_fat_percent'] == 'קליפר'"> ({{ selectedB['ntq_fat_caliper_status'] }})</span>: <span class="font-bold">{{ selectedB['ntq_fat_mass'] }}</span>
                    </p>
                    <p *ngIf="selectedB['ntq_skinny_mass']">
                        מסה רזה<span *ngIf="selectedB['ntq_fat_percent'] == 'קליפר'"> ({{ selectedB['ntq_fat_caliper_status'] }})</span>: <span class="font-bold">{{ selectedB['ntq_skinny_mass'] }}</span>
                    </p>
                    <ng-container *ngIf="notesFormB.value._id && selectedB['ntq_fat_percent'] != 'ללא אחוז שומן'" [ngTemplateOutlet]="antroRadiosTemplate" [ngTemplateOutletContext]="{
                        selectedForm: notesFormB,
                        fieldName: 'notes_nut_fat'
                    }">
                    </ng-container>
                    <!-- <notes *ngIf="notesFormB.value._id" [notesArr]="notesList['notes_nut_fat']" [selectedForm]="notesFormB"
                        [fieldName]="'notes_nut_fat'"></notes> -->
                </div>
            </div>


            <div class="row">
                <div class="w49" *ngIf="selectedA" style="position: relative;">
                    <div *ngIf="fatValidityObjA && fatValidityStyleA">
                        <mat-checkbox [formControl]="notesFormA.get('ntq_show_graph')" style="position: absolute; right: 20px; top: 20px;"></mat-checkbox>
                    </div>

                    <div class="m-auto m-t-40" style="position: relative;" *ngIf="fatValidityObjA && fatValidityStyleA">
                        <ul class="chart-fat">
                            <li [style]="fatValidityStyleA.a">
                                <!-- <span [style]="fatValidityStyle.a">תת שומן</span> -->
                                <div [style]="fatValidityStyleA.a">{{ fatValidityObjA.a }}</div>
                            </li>
                            <li [style]="fatValidityStyleA.b">
                                <!-- <span [style]="fatValidityStyle.b">תקין-בריא</span> -->
                                <div [style]="fatValidityStyleA.b">{{ fatValidityObjA.b }}</div>
                            </li>
                            <li [style]="fatValidityStyleA.c">
                                <!-- <span [style]="fatValidityStyle.c">עודף שומן</span> -->
                                <div [style]="fatValidityStyleA.c">{{ fatValidityObjA.c }}</div>
                            </li>
                            <li style="--percentage : 100;">
                                <!-- <span style="--percentage : 100;">השמנה</span> -->
                                <div style="--percentage : 100;">{{ fatValidityObjA.d }}</div>
                            </li>
                        </ul>
                        <div class="fat-pointer" [style]="fatValidityStyleA.fat"><div>{{ fatValidityObjA.fat }}</div>
                        </div>
                    </div>
                    <div class="chart-fat-guide mat-elevation-z3" *ngIf="fatValidityObjA && fatValidityStyleA">
                        <div class="color-box a"></div>
                        <div class="label-box">
                            תת שומן
                        </div>
                        <div class="color-box b"></div>
                        <div class="label-box">
                            תקין-בריא
                        </div>
                        <div class="color-box c"></div>
                        <div class="label-box">
                            עודף שומן
                        </div>
                        <div class="color-box d"></div>
                        <div class="label-box">
                            השמנה
                        </div>
                    </div>
                </div>
                <div class="w49" *ngIf="selectedB" style="position: relative;">
                    <div *ngIf="fatValidityObjB && fatValidityStyleB">
                        <mat-checkbox [formControl]="notesFormB.get('ntq_show_graph')" style="position: absolute; left: 20px; top: 20px;"></mat-checkbox>
                    </div>

                    <div class="m-auto m-t-40" style="position: relative;" *ngIf="fatValidityObjB && fatValidityStyleB">
                        <ul class="chart-fat">
                            <li [style]="fatValidityStyleB.a">
                                <!-- <span [style]="fatValidityStyle.a">תת שומן</span> -->
                                <div [style]="fatValidityStyleB.a">{{ fatValidityObjB.a }}</div>
                            </li>
                            <li [style]="fatValidityStyleB.b">
                                <!-- <span [style]="fatValidityStyle.b">תקין-בריא</span> -->
                                <div [style]="fatValidityStyleB.b">{{ fatValidityObjB.b }}</div>
                            </li>
                            <li [style]="fatValidityStyleB.c">
                                <!-- <span [style]="fatValidityStyle.c">עודף שומן</span> -->
                                <div [style]="fatValidityStyleB.c">{{ fatValidityObjB.c }}</div>
                            </li>
                            <li style="--percentage : 100;">
                                <!-- <span style="--percentage : 100;">השמנה</span> -->
                                <div style="--percentage : 100;">{{ fatValidityObjB.d }}</div>
                            </li>
                        </ul>
                        <div class="fat-pointer" [style]="fatValidityStyleB.fat"><div>{{ fatValidityObjB.fat }}</div>
                        </div>
                    </div>
                    <div class="chart-fat-guide mat-elevation-z3" *ngIf="fatValidityObjB && fatValidityStyleB">
                        <div class="color-box a"></div>
                        <div class="label-box">
                            תת שומן
                        </div>
                        <div class="color-box b"></div>
                        <div class="label-box">
                            תקין-בריא
                        </div>
                        <div class="color-box c"></div>
                        <div class="label-box">
                            עודף שומן
                        </div>
                        <div class="color-box d"></div>
                        <div class="label-box">
                            השמנה
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row">
                <div class="w30" *ngIf="selectedA">
                    <p *ngIf="selectedA['ntq_fat_validity_label']">
                        תקינות אחוז שומן: 
                        <span class="font-bold">{{ selectedA['ntq_fat_validity_label'] }}</span>
                    </p>
                </div>
                <div class="w30 middle" *ngIf="selectedA && selectedB">
                </div>
                <div class="w30" *ngIf="selectedB">
                    <p *ngIf="selectedB['ntq_fat_validity_label']">
                        תקינות אחוז שומן: 
                        <span class="font-bold">{{ selectedB['ntq_fat_validity_label'] }}</span>
                    </p>
                </div>
            </div> -->
        </div>
        <div *ngIf="oldValues.length > 0" class="">
            <h4 class="ul-text">הערות נתונים אנתרופומטריים</h4>
            <div class="row">
                <div class="w40" *ngIf="selectedA">
                    <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('ntq_notes')" fieldKey="A" [oldNotes]="notesFormA.get('notes_nut_body')" [timeCreated]="selectedA.createdAt"(addNoteEvent)="addNote($event, 'ntq', 'A')" (editNoteEvent)="editNote($event, 'ntq', 'A')"></notes>
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">

                </div> -->
                <div class="w40" *ngIf="selectedB">
                    <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('ntq_notes')" fieldKey="A" [oldNotes]="notesFormB.get('notes_nut_body')" [timeCreated]="selectedB.createdAt"></notes>
                </div>
            </div>

            <!-- <h4 class="ul-text">דיווח למועדון</h4>
            <div class="row">
                <div class="w40" *ngIf="selectedA">
                    <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('ntq_notes')" fieldKey="B" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'ntq', 'B')" (editNoteEvent)="editNote($event, 'ntq', 'B')"></notes>
                </div>

                <div class="w40" *ngIf="selectedB">
                    <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('ntq_notes')" fieldKey="B" [timeCreated]="selectedB.createdAt"></notes>
                </div>
            </div> -->

            <ng-container *ngIf="oldNotesFlag || (selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">

                <h4 class="ul-text">דיווח למועדון</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA">
                        <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('ntq_notes')" fieldKey="B" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'ntq', 'B')"(editNoteEvent)="editNote($event, 'ntq', 'B')"></notes>
                    </div>

                    <div class="w40" *ngIf="selectedB">
                        <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('ntq_notes')" fieldKey="B" [timeCreated]="selectedB.createdAt"></notes>
                    </div>
                </div>

            </ng-container>

            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">

                <h4 class="font-bold text-info ul-text">דיווח למועדון</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA && notesFormA && sharedNotesFormA">
                        <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [selectedGroup]="notesFormA.get('ntq_notes')" [sharedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s11" [notesArr]="notesList" (addNoteEvent)="addNote($event, quizPrefix, 's11')" (editNoteEvent)="editNote($event, quizPrefix, 's11')"></notes>
                    </div>

                    <div class="w40" *ngIf="selectedB">
                        <notes *ngIf="selectedB._id" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="selectedB[quizPrefix + '_notes']" fieldKey="s11"></notes>
                    </div>
                </div>

            </ng-container>

            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">

                <h4 class="font-bold text-info ul-text">הערות לתחילת הדוח</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA && notesFormA && sharedNotesFormA">
                        <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [selectedGroup]="notesFormA.get('ntq_notes')" [sharedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s1" [notesArr]="notesList" (addNoteEvent)="addNote($event, quizPrefix, 's1')" (editNoteEvent)="editNote($event, quizPrefix, 's1')"></notes>
                    </div>

                    <!-- <div class="w40" *ngIf="selectedB">
                        <notes *ngIf="selectedB._id" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="selectedB['ntq_notes']" fieldKey="s1"></notes>
                    </div> -->
                    <!-- <div class="w40" *ngIf="notesFormB">
                        <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [notesArr]="notesList" [selectedGroup]="notesFormB.get('ntq_notes')" fieldKey="s1"></notes>
                    </div> -->

                    <div class="w40" *ngIf="selectedB">
                        <notes *ngIf="selectedB._id" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="selectedB[quizPrefix + '_notes']" fieldKey="s1"></notes>
                    </div>
                </div>

            </ng-container>

            <!-- <ng-container *ngIf="selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z';else templateS1">

            </ng-container> -->

            <!-- <ng-template #templateS1>
                <h4 class="font-bold text-info ul-text">הערות לתחילת הדוח</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA && sharedNotesFormA && allFdqValues">
                        <notes *ngIf="sharedNotesFormA.value._id && ((selectedA._id | fdqDetails : allFdqValues : quizPrefix) ? (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id === sharedNotesFormA.value._id : true)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s1" (addNoteEvent)="addNote($event, quizPrefix, 's1')" (editNoteEvent)="editNote($event, quizPrefix, 's1')"></notes>
                        <notes *ngIf="(selectedA._id | fdqDetails : allFdqValues : quizPrefix) && (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id !== sharedNotesFormA.value._id" [selectedValue]="(selectedA._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s1" [notesArr]="notesList" [isFemale]="(user.usr_gender === 'נקבה')"></notes>
                    </div>
                    
                    // <div class="w40" *ngIf="selectedB && sharedNotesFormB">
                        <notes *ngIf="sharedNotesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="sharedNotesFormB.get('fdq_notes')" fieldKey="s1"></notes>
                    </div> //
                    <div class="w40" *ngIf="selectedB && allFdqValues">
                        <notes *ngIf="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s1"></notes>
                    </div>
                </div>                
            </ng-template> -->

            <div class="row">
                <div class="w40 buttons-row" *ngIf="selectedA">
                    <button class="plus-button" mat-raised-button (click)="deleteQuiz(selectedIdA)">
                        {{'general.deleteQuiz' | translate}}
                        <mat-icon>delete</mat-icon>
                    </button>
                    <!-- <button [routerLink]="['/Nuttestquiz/', selectedIdA]" class="plus-button"  mat-raised-button>עבור לטופס</button> -->
                    <a target="quizwindow" [routerLink]="['/Nuttestquiz/', selectedIdA]" class="plus-button" mat-raised-button>עבור לטופס</a>
                    <mat-slide-toggle [checked]="notesFormA.value.ntq_locked" (change)="toggleQuizLock($event, notesFormA)">{{'general.lockQuiz' | translate}}</mat-slide-toggle>
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">
                </div> -->
                <div class="w40 buttons-row" *ngIf="selectedB">
                    <button class="plus-button" mat-raised-button (click)="deleteQuiz(selectedIdB)">
                        {{'general.deleteQuiz' | translate}}
                        <mat-icon>delete</mat-icon>
                    </button>
                    <!-- <button [routerLink]="['/Nuttestquiz/', selectedIdB]" class="plus-button" mat-raised-button>עבור לטופס</button> -->
                    <a target="quizwindow" [routerLink]="['/Nuttestquiz/', selectedIdB]" class="plus-button" mat-raised-button>עבור לטופס</a>
                    <mat-slide-toggle [checked]="notesFormB.value.ntq_locked" (change)="toggleQuizLock($event, notesFormB)">{{'general.lockQuiz' | translate}}</mat-slide-toggle>
                </div>
            </div>

            <!-- <div class="mt125 text-center">
                <button mat-raised-button color="primary" class="w30" (click)="submitForm()">שמור הערות</button>
            </div> -->

        </div>

    </mat-expansion-panel>

    <!-- <charts-section [user]="user"></charts-section> -->

</div>

<ng-template #antroRadiosTemplate let-selectedForm="selectedForm" let-fieldName="fieldName">
    <div class="antro-radios text-themecolor">
        <mat-radio-group [formControl]="selectedForm.get(fieldName)">
            <mat-radio-button value="תקין">תקין</mat-radio-button>
            <mat-radio-button value="לא תקין">לא תקין</mat-radio-button>
        </mat-radio-group>
    </div>
</ng-template>
