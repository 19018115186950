import { Component, Input, Output, EventEmitter, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { appState } from '@services/app-state';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

import { RestApiService } from '@noammazuz/vzn-feathers';

import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import { Appcalcs } from '@services/app-calcs';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'percentageFlag'
})
export class PercentageFlagPipe implements PipeTransform {
  transform(valuesArray: any, key: string, value: any): any {
    if (!valuesArray || !key || !value) return 0;
    let result = {
      date: '',
      num: 0
    };
    for (const arrVal of valuesArray) {
      const numA = arrVal[key];
      const numB = value;
      const n1 = typeof numA === 'string' ? numA.replace(/[^0-9.]/g,'') : numA;
      const n2 = typeof numB === 'string' ? numB.replace(/[^0-9.]/g,'') : numB;
      // return +(Math.abs(n1 * 1 - n2 * 1).toFixed(1));
      const res = +((n1 * 1 - n2 * 1).toFixed(1));
      const diff = Math.abs(res);
      const diffNum = Math.abs(result.num);
      if (diff > 5 && diff > diffNum) {
        // result.date = moment(arrVal['ntq_test_date']).format("DD/MM/YYYY");
        result.date = arrVal['ntq_test_date'];
        result.num = res;
      }
    }
    if (result.date) {
      result.date = moment(result.date).format("DD/MM/YYYY");
    }
    return result;
  }
}

@Component({
  selector: 'antropometrics-section',
  templateUrl: './antropometrics.component.html'
})
export class AntropometricsComponent {
  @Output() addNoteEvent: EventEmitter<any> = new EventEmitter();
  @Output() editNoteEvent: EventEmitter<any> = new EventEmitter();

  @Output() busyState: EventEmitter<boolean> = new EventEmitter();
  @Output() updateFollow: EventEmitter<any> = new EventEmitter();

  @Input() user: any;
  @Output() kcalData: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onNew: EventEmitter<any> = new EventEmitter();
  @Output() selectedNuttest: EventEmitter<any> = new EventEmitter();
  
  // @Input() allNotes = [];
  @Output() linkedNotesEvent: EventEmitter<any> = new EventEmitter();
  @Input() notesList = [];

  @Input() sharedNotesFormA: FormGroup;
  // @Input() sharedNotesFormB: FormGroup;
  @Input() allFdqValues: any;

  @Input() oldNotesFlag?: boolean;

  quizPrefix = 'ntq';

  notesFormA: FormGroup;
  notesFormB: FormGroup;

  calcs = Appcalcs;
  calcDiff = Appcalcs.calcDiff;
  calcP = (n1: number, n2: number) => {
    const diff = Math.abs(this.calcDiff(n1, n2) || 0);
    return diff > 5;
  }

  fatValidityObjA: any;
  fatValidityStyleA: any;
  
  fatValidityObjB: any;
  fatValidityStyleB: any;
  
  oldValues = [];

  selectedIdA: string;
  selectedIdB: string;

  selectedA: any;
  selectedB: any;

  restService: any;

  appState = appState;

  chosenLastNtq: any = null;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    public restApiService: RestApiService<any>
  ) {
    this.restService = this.restApiService.service('nuttestquiz');

    this.notesFormA = this.formBuilder.group({
      _id: [''],
      ntq_locked: [false],
      ntq_notes: this.formBuilder.group({
        A: this.formBuilder.array([]),

        B: this.formBuilder.array([]),
        
        s1: this.formBuilder.array([]),
        s11: this.formBuilder.array([]),
      }),

      ntq_show_graph: [false],
      ntq_show_fat_per: [false],

      notes_nut_height: [''],

      notes_nut_weight: [''],

      notes_nut_bmi: [''],

      notes_nut_fat: [''],

      notes_nut_body: [''],

      ntq_dev_status: [''],
  ntq_height_trend: [''],
  ntq_bmi_status: [''],
  ntq_bmi_trend: [''],
  ntq_fat_per_status: [''],
  ntq_fat_per_trend: [''],
  usr_cycle_date: [''],


    });

    this.notesFormB = this.formBuilder.group({
      _id: [''],
      ntq_locked: [false],
      ntq_notes: this.formBuilder.group({
        A: this.formBuilder.array([]),

        B: this.formBuilder.array([]),
        
        // s1: this.formBuilder.array([]),
        // s11: this.formBuilder.array([]),
      }),

      ntq_show_graph: [false],
      ntq_show_fat_per: [false],

      notes_nut_height: [''],

      notes_nut_weight: [''],

      notes_nut_bmi: [''],

      notes_nut_fat: [''],

      notes_nut_body: [''],
      notes_ntq_extra:[''],
      ntq_dev_status: [''],
  ntq_height_trend: [''],
  ntq_bmi_status: [''],
  ntq_bmi_trend: [''],
  ntq_fat_per_status: [''],
  ntq_fat_per_trend: [''],
  usr_cycle_date: [''],

    });

  }

  async newQuiz() {
    await this.restService.create({
      ntq_usr_id: this.user._id,
      ntq_form_is_real: true
    });
    this.fetchOldValues(true);
  }

  async newAntroQuizGuard() {
    this.onNew.emit(() => { this.newAntroQuiz() });
  }
  async newAntroQuiz() {
    // const now = new Date();
    const res = await this.restService.create({
      ntq_usr_id: this.user._id,
      ntq_form_is_real: true,
      // ntq_test_date: now
    });
    if (res && res._id) {
      // this.router.navigate([`Nuttestquiz/${res._id}`]);
      // return;
      // window.open(`Nuttestquiz/${res._id}`, 'quizwindow');
      window.open(`Nuttestquiz/${res._id}`);
    }
    this.fetchOldValues(true);
  }

  async deleteQuiz(quizId: string) {
    this.onDelete.emit(() => { this.deleteOk(quizId) });
  }

  async deleteOk(quizId: string) {
    await this.restService.delete(quizId);
    this.fetchOldValues(true);
  }

  async toggleQuizLock(event: MatSlideToggleChange, selected: FormGroup) {
    const lockedField = 'ntq_locked';
    if (selected && selected.value && selected.value._id) {
      const res = await this.restService.patch(selected.value._id, {
        [lockedField]: event.checked
      });
      if (res && res[lockedField] === event.checked) {
        event.source.checked = res[lockedField];
        selected.controls[lockedField].reset(res[lockedField]);
        return;
      }
    }
    event.source.checked = !event.checked;
  }

  async fetchOldValues(reset?: boolean, silent?: boolean) {
    try {
      let res = await this.restService.find({ query: {
        $paginate: false,
        // $select: [ '_id', 'ntq_test_date', 'ntq_usr_id', 'ntq_height', 'ntq_weight', 'ntq_factor', 'ntq_fat_percent', 'ntq_fat_percent_num', 'ntq_fat_caliper_status', 'savedAt', 'createdAt', 'is_by_user' ],
        ntq_usr_id: this.user._id,
        ntq_form_is_real: true,
        $sort: {
          ntq_test_date: -1,
          createdAt: -1
        }
      } });

      if (res.length > 0){
        // res.sort((a, b) => {
        //   const numA = a?.is_by_user ? 1 : 0;
        //   const numB = b?.is_by_user ? 1 : 0;
        //   return numA - numB;
        // });

        for (const val of res) {
          this.calcs.updatePercents(val, this.user);
        }
        const weight = +(res[0]['ntq_weight']);
        const factor = res[0]['ntq_factor'] ? Math.max(+(res[0]['ntq_factor']), 1) : 1;
        const testDate = res[0]['ntq_test_date'];
        const rmr = res[0]['ntq_rmr'];
        // to emit last quiz calcs to the parent and then to practice section
        this.kcalData.emit({weight, rmr, factor, testDate});

        const noDateIndex = res.findIndex(el => !el.ntq_test_date);
        if (noDateIndex > 0) { // min 2nd item
          const noDateTime = new Date(res[noDateIndex].createdAt).getTime();
          const firstItemTime = new Date(res[0].ntq_test_date).getTime();
          if (noDateTime > firstItemTime) {
            res.splice(0, 0, res.splice(noDateIndex, 1)[0]);
          }

          // const toIndex = res.findIndex(el => new Date(res[noDateIndex].createdAt).getTime() < new Date(el.ntq_test_date).getTime());
          // res.splice(toIndex + 1, 0, res.splice(noDateIndex, 1)[0]);
        }

        console.log(res);
        this.oldValues = res;

      } else {
        this.oldValues = [];
      }

      if (reset) {
        return await this.resetToLastTwo(silent);
      }

    } catch (e) {
      console.error(e);
    }
  }

  async resetToLastTwo(silent?: boolean) {
    const values = this.oldValues;
    if (values.length === 0) {
      delete this.selectedIdA;
      delete this.selectedA;
      this.notesFormA.reset();
      delete this.selectedIdB;
      delete this.selectedB;
      this.notesFormB.reset();
      return;
    }
    if (values.length === 1) {
      delete this.selectedIdB;
      delete this.selectedB;
      this.notesFormB.reset();
    }
    // delete this.selectedIdA;
    if (values[0] && values[0]._id && values[0]._id !== this.selectedIdA) {
      delete this.selectedA;
      this.notesFormA.reset();
      this.selectedIdA = values[0]._id;
      await this.selectDateA(this.selectedIdA);
    }
    if (silent) {
      return;
    }
    // delete this.selectedIdB;
    if (values[1] && values[1]._id && values[1]._id !== this.selectedIdB) {
      delete this.selectedB;
      this.notesFormB.reset();
      this.selectedIdB = values[1]._id;
      await this.selectDateB(this.selectedIdB);
      this.chosenLastNtq = this.selectedB;
    }
  }

  async ngOnInit() {
    await this.fetchOldValues(true);
  }

  resetNotes(notesGroup: FormGroup, dataNotes) {
    for (const noteKey in notesGroup.value) {
      let notes: FormArray = notesGroup.get(noteKey) as FormArray;
      notes.clear();
      if (dataNotes && dataNotes[noteKey]) {
        for (const n of dataNotes[noteKey]) {
          notes.push(new FormControl());
        }
      }
    }
  }

  refreshFormA(value) {
    const notesGroup = this.notesFormA.get('ntq_notes') as FormGroup;
    this.resetNotes(notesGroup, value['ntq_notes']);
    this.notesFormA.reset(value);
    this.selectedA = value;
    this.calcs.updatePercents(this.selectedA, this.user);
    // this.kcalData.emit({weight: +(data['ntq_weight']), rmr: this.calcRMR(data)});
    delete this.fatValidityObjA;
    delete this.fatValidityStyleA;
    const fatValidity = this.calcs.getFatValidity(this.selectedA, this.user.usr_gender_type);
    if (fatValidity && fatValidity.fat) {
      const a = fatValidity.a/fatValidity.d*100;
      const b = fatValidity.b/fatValidity.d*100;
      const c = fatValidity.c/fatValidity.d*100;
      const d = 100;
      const fatStyle = fatValidity.fat/fatValidity.d*100;
      this.fatValidityObjA = fatValidity;
      this.fatValidityStyleA = {
        a: this.sanitizer.bypassSecurityTrustStyle('--percentage : ' + a),
        b: this.sanitizer.bypassSecurityTrustStyle('--percentage : ' + b),
        c: this.sanitizer.bypassSecurityTrustStyle('--percentage : ' + c),
        d: this.sanitizer.bypassSecurityTrustStyle('--percentage : ' + d),
        fat: this.sanitizer.bypassSecurityTrustStyle('--percentage : ' + fatStyle)
      }
    }

    // this.selectedNuttest.emit(this.selectedA);
    this.emitShowFatPercentage();
  }

  refreshFormB(value) {
    const notesGroup = this.notesFormB.get('ntq_notes') as FormGroup;
    this.resetNotes(notesGroup, value['ntq_notes']);
    this.notesFormB.reset(value);
    this.selectedB = value;
    this.calcs.updatePercents(this.selectedB, this.user);
    // this.kcalData.emit({weight: +(data['ntq_weight']), rmr: this.calcRMR(data)});
    delete this.fatValidityObjB;
    delete this.fatValidityStyleB;
    const fatValidity = this.calcs.getFatValidity(this.selectedB, this.user.usr_gender_type);
    if (fatValidity && fatValidity.fat) {
      const a = fatValidity.a/fatValidity.d*100;
      const b = fatValidity.b/fatValidity.d*100;
      const c = fatValidity.c/fatValidity.d*100;
      const d = 100;
      const fatStyle = fatValidity.fat/fatValidity.d*100;
      this.fatValidityObjB = fatValidity;
      this.fatValidityStyleB = {
        a: this.sanitizer.bypassSecurityTrustStyle('--percentage : ' + a),
        b: this.sanitizer.bypassSecurityTrustStyle('--percentage : ' + b),
        c: this.sanitizer.bypassSecurityTrustStyle('--percentage : ' + c),
        d: this.sanitizer.bypassSecurityTrustStyle('--percentage : ' + d),
        fat: this.sanitizer.bypassSecurityTrustStyle('--percentage : ' + fatStyle)
      }
    }
    
    // this.selectedNuttest.emit(this.selectedB);
    this.emitShowFatPercentage();
  }

  async selectDateA(newVal){
    // const data = await this.restService.get(newVal).catch(e => { console.error(e) });
    // const res = await this.restService.find({
    //   query: {
    //     $nutri: true,
    //     $limit: 1,
    //     _id: newVal,
    //   },
    // }).catch(e => { console.error(e) });
    // const data = res?.data[0];
    const data = this.oldValues.find(val => val._id === newVal);
    if (data) {
      this.notesFormA.reset();
      this.refreshFormA(data); 
    }
  }
  async selectDateB(newVal){
    // const data = await this.restService.get(newVal).catch(e => { console.error(e) });
    // const res = await this.restService.find({
    //   query: {
    //     $nutri: true,
    //     $limit: 1,
    //     _id: newVal,
    //   },
    // }).catch(e => { console.error(e) });
    // const data = res?.data[0];
    const data = this.oldValues.find(val => val._id === newVal);
    if (data) {
      this.notesFormB.reset();
      this.refreshFormB(data); 
    }
  }

  // async submitForm(){
  //   this.busyState.emit(true);
  //   const form = this.notesFormA;
  //   let res: any;

  //   if (form && form.value._id && form.value._id !== '') { 
  //     res = await this.restService.patch(form.value._id, form.value);
  //     if (res && res._id) {
  //       form.reset(res);
  //       this.showStatus("נשמר בהצלחה", null);
  //       if (res.follow_flag) {
  //         this.updateFollow.emit(res);
  //         setTimeout(() => {
  //           this._snackBar.open('המשתמש נכנס למעקב', 'סגור', {
  //             duration: 0,
  //           });
  //         }, 2000);
  //       }
  //     }
  //   }
  //   this.busyState.emit(false);
  //   return res;
  // }

  showStatus(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getObjKeys(obj) {
    return Object.keys(obj) || [];
  }

  getDirty() {
    const formA = this.notesFormA;
    const formB = this.notesFormB;
    // return (formA && formA.dirty) || (formB && formB.dirty);
    return (formA && formA.dirty);
  }

  getLocked() {
    const lockedField = 'ntq_locked';
    const formA = this.notesFormA;
    // const formB = this.notesFormB;
    // return (formA && formA.dirty) || (formB && formB.dirty);
    return (formA && formA.value[lockedField]);
  }

  setChosenLastNtq(e: any, value: any) {
    e.stopPropagation();
    this.chosenLastNtq = value;
  }

  linkedNote(note: any) {
    this.linkedNotesEvent.emit(note);
  }

  addNote(contentAndField: any = {}, quiz_prefix: string, quiz_note_type: string) {
    const { content, content_F, acFormField } = contentAndField;
    this.addNoteEvent.emit({ content, content_F, quiz_prefix, quiz_note_type, acFormField });
  }

  editNote(idAndField: any = {}, quiz_prefix: string, quiz_note_type: string) {
    const { id, field } = idAndField;
    this.editNoteEvent.emit({ id, field, quiz_prefix, quiz_note_type });
  }

  addNoteToNotesObj(quiz_note_type: string, newNote: FormControl) {
    const notesGroup = this.notesFormA.get('ntq_notes') as FormGroup;
    const fieldArray = notesGroup.get(quiz_note_type) as FormArray;
    if (!newNote.value?.note_id || !fieldArray.value || fieldArray.value.findIndex(val => val && val.note_id === newNote.value.note_id) > -1) {
      return;
    }
    fieldArray.push(newNote);
    fieldArray.markAsDirty();
  }

  removeNote(quiz_note_type: string, noteId: string) {
    const notesGroup = this.notesFormA.get('ntq_notes') as FormGroup;
    const fieldArray = notesGroup.get(quiz_note_type) as FormArray;
    if (fieldArray) {
      const idx = fieldArray.value.findIndex(val => val && val.note_id === noteId); // || (val.note_custom && val.note_custom === noteObj.note_custom)
      if (idx > -1) {
        fieldArray.removeAt(idx);
        fieldArray.markAsDirty();
      }      
    }
  }
  async emitShowFatPercentage() {
    const formAValue = this.notesFormA.get('ntq_show_fat_per')?.value;
    const formBValue = this.notesFormB.get('ntq_show_fat_per')?.value;
    
    // Only use form A's value if it exists and is not null/undefined
    // Otherwise fallback to form B's value
    const showFatPercentage = formAValue !== null && formAValue !== undefined 
      ? formAValue 
      : (formBValue || false);

    // Update state through event emitter
    this.updateFollow.emit({
      showFatPercentage: showFatPercentage
    });

    // Save to database if we have a selected form
    if (this.selectedA && this.selectedA._id) {
      await this.restService.patch(this.selectedA._id, {
        ntq_show_fat_per: formAValue
      });
    }
  }
  onChange(event: any, formGroupName: string, fieldName: string) {
    const formGroup: FormGroup = this[formGroupName] as FormGroup;
    if (formGroup && formGroup.get(fieldName)) {
      formGroup.get(fieldName).setValue(event.value);
      this.updateFollow.emit({
        [fieldName]: event.value
      });
      formGroup.get(fieldName).markAsDirty();

    } else {
      console.error(`Form group or field not found: ${formGroupName}.${fieldName}`);
    }
  }
  
}
