<div class="basic-form mt125" *ngIf="!appState.user.usr_type">
    <mat-card class="text-center">
        <h1>בחרו סוג משתמש</h1>
        <div class="buttons-user-type w65">
            <button type="button" mat-raised-button [disabled]="isBusy" [ngClass]="{ 'chosen': chosenType === 'player' }" (click)="chosenType = 'player'">
                <span class="font-20">ספורטאי/ת</span>
            </button>
            <button type="button" mat-raised-button [disabled]="isBusy" [ngClass]="{ 'chosen': chosenType === 'parent' }" (click)="chosenType = 'parent'">
                <span class="font-20">הורה</span>
            </button>
        </div>
        <div>
            <button mat-raised-button color="primary" [disabled]="!chosenType || isBusy" (click)="chooseType(chosenType)">
                <span class="font-14">המשך</span>
            </button>
        </div>
    </mat-card>
</div>

<div class="full-loader" *ngIf="busy">
    <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
    </div>
</div>

<div class="profile-page" *ngIf="appState.user.usr_type" [ngClass]="{'loading-margin': busy}">

    <form novalidate class="basic-form" [formGroup]="profileForm">

        <div class="row">
            <h1>{{'profile.TITLE' | translate}}</h1>
            <!-- <button type="button" *ngIf="isOtherUser" class="plus-button" mat-raised-button [routerLink]="['/Nutritionistform/', usr_id]">
                חזור לטופס תזונאי
                <mat-icon>undo</mat-icon>
            </button> -->
        </div>

        <mat-card *ngIf="!(((appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'admin') && profileForm.value.usr_type) || profileForm.value._id)">
            <div class="spinner">
                <div class="double-bounce1"></div>
                <div class="double-bounce2"></div>
            </div>
        </mat-card>

        <ng-container *ngIf="((appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'admin') && profileForm.value.usr_type) || profileForm.value._id">
            <mat-card>
                <div *ngIf="appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'admin'" style="display: flex;justify-content: center;">
                    <mat-radio-group formControlName="usr_type">
                        <mat-radio-button value="admin" *ngIf="appState.user.usr_type === 'superadmin'">מנהל</mat-radio-button>
                        <mat-radio-button value="nutri" *ngIf="appState.user.usr_type === 'superadmin'">תזונאי</mat-radio-button>
                        <mat-radio-button value="coach">מאמן</mat-radio-button>
                        <mat-radio-button value="player">שחקן</mat-radio-button>
                        <mat-radio-button value="parent">הורה</mat-radio-button>
                    </mat-radio-group>
                    <mat-checkbox class="m-r-20 checkbox" formControlName="usr_is_validated">סיסמא קבועה?</mat-checkbox>
                </div>
                <!-- <div *ngIf="appState.user.usr_type == 'superadmin' && allClubs" class="text-center">
                    <mat-form-field *ngIf="profileForm.value.usr_type !== 'parent'" class="w45">
                        <mat-select formControlName="usr_club_id" placeholder="שיוך למועדון">
                            <mat-option [value]="''">ללא שיוך</mat-option>
                            <mat-option *ngFor="let club of allClubs" [value]="club._id">{{ club.clb_name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->
                <h4>תמונת פרופיל:</h4>
                <div>
                    <div *ngIf="formInitiated">
                        <vzn-media-files
                            [uploadFolder]="base_image_upload_folder_path"
                            [maxImageSize]="1500"
                            [width]="'auto'"
                            [height]="'150px'"
                            [assetMargin]="'5px'"
                            [uploadUrlService]="uploadUrlService"
                            [formArrayName]="'usr_profile_pic_asset_arr'"
                            [acceptedFiles]="'image/jpeg, image/x-png, image/png, image/webp'"
                            [limitToOne]="true"
                            [disabled]="isBusy"
                            (onAssetClick)="assetClick($event)"
                            (onUploadStart)="isBusy=true"
                            (onUploadEnd)="isBusy=false"
                        >
                        </vzn-media-files>
                    </div>
                    <mat-error class="custom-error"
                        *ngIf="appState.user.usr_type === 'player' && formInitiated && profileForm.value.usr_profile_pic_asset_arr?.length == 0">
                        עליך לבחור תמונה
                    </mat-error>
                </div>
                <div>
                    <mat-form-field class="w45">
                        <input formControlName="usr_fname" matInput placeholder="שם פרטי">
                        <mat-error>
                            {{getFieldError('usr_fname')}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="w45">
                        <input formControlName="usr_lname" matInput placeholder="שם משפחה">
                        <mat-error>
                            {{getFieldError('usr_lname')}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="w45">
                        <input type="email" formControlName="usr_email" matInput placeholder="מייל">
                        <mat-error>
                            {{getFieldError('usr_email')}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="w45">
                        <input type="text" digitOnly maxlength="9" inputmode="numeric" pattern="[0-9]*" formControlName="usr_identification_number" matInput placeholder="מספר ת.ז">
                        <mat-error>
                            {{getFieldError('usr_identification_number')}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="w45">
                        <input type="text" digitOnly maxlength="13" inputmode="numeric" pattern="[0-9]*" formControlName="usr_phone" matInput placeholder="פלאפון">
                        <mat-error>
                            {{getFieldError('usr_phone')}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="w45">
                        <input #formDate matInput [matDatepicker]="dpBdate" [min]="minDate" [max]="maxDate" placeholder="תאריך לידה" formControlName="usr_birth_date">
                        <mat-datepicker-toggle matSuffix [for]="dpBdate"></mat-datepicker-toggle>
                        <mat-datepicker #dpBdate startView="multi-year"></mat-datepicker>
                        <mat-error>
                            {{getFieldError('usr_birth_date')}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-radio-group formControlName="usr_gender">
                        <mat-radio-button value="זכר">זכר</mat-radio-button>
                        <mat-radio-button value="נקבה">נקבה</mat-radio-button>
                    </mat-radio-group>
                    <mat-error class="custom-error" *ngIf="formInitiated && profileForm.value.usr_gender == ''">
                        עליך לבחור מין
                    </mat-error>
                </div>

            </mat-card>

            <mat-card *ngIf="appState.user.usr_type === 'superadmin' && profileForm.value" class="profile-user-details">
                <ul>
                    <!-- <li>
                        מספר דוחות בחבילה: 
                        <span class="dl dir-ltr">
                            {{ userData.total_reports || 0 }} / {{ userData.usr_report_count || 0 }}
                        </span>
                    </li> -->
                    <li>
                        מספר דוחות שהופקו:
                        <span class="dl dir-ltr">
                            {{ profileForm.value.total_reports || 0 }}
                        </span>
                    </li>
                    <li>
                        מספר דוחות בחבילה:
                        <mat-form-field *ngIf="appState.user.usr_type === 'superadmin'" class="w15 in-row-field wide">
                            <input type="text" digitOnly maxlength="2" inputmode="numeric" pattern="[0-9]*" [formControl]="profileForm.get('usr_report_count')" matInput>
                        </mat-form-field>
                        <span *ngIf="appState.user.usr_type !== 'superadmin'">
                            {{ profileForm.value.usr_report_count || 0 }}
                        </span>
                    </li>
                    <!-- <li>
                        מדידה פיזית: 
                        <mat-radio-group [formControl]="profileForm.get('usr_is_measurement')">
                            <mat-radio-button [value]="true">{{'general.yes' | translate}}</mat-radio-button>
                            <mat-radio-button [value]="false">{{'general.no' | translate}}</mat-radio-button>
                        </mat-radio-group>
                    </li> -->
                    <li>
                        תאריך מדידה: 
                        <mat-form-field *ngIf="appState.user.usr_type === 'superadmin'" class="w30 in-row-field wide">
                            <input readonly #timingDate [formControl]="profileForm.get('usr_measurement_date')" matInput [matDatepicker]="dpInput">
                            <mat-datepicker-toggle matSuffix [for]="dpInput"></mat-datepicker-toggle>
                            <mat-datepicker #dpInput disabled="false"></mat-datepicker>
                        </mat-form-field>
                        <span *ngIf="appState.user.usr_type !== 'superadmin'">
                            {{ profileForm.value.usr_measurement_date | dateFormat : 'DD/MM/yyyy' }}
                        </span>
                    </li>
                    <li >שעת מדידה:
                        <mat-form-field *ngIf="appState.user.usr_type ==='superadmin'" class=" w30 in-row-field wide close-to-line"
                            style="margin: 0;">
                            <input class="new-time-input" ampm="false" [format]=24 matInput type="time" [formControl]="profileForm.get('usr_measurement_hour')" />
                        </mat-form-field>
                        <span *ngIf="appState.user.usr_type !== 'superadmin'">
                            {{ profileForm.value.usr_measurement_hour }}
                        </span>
                     </li>
                    <!-- <li>
                        פגישת וידאו/פרונטלית שבוצעו: 
                        <mat-form-field *ngIf="appState.user.usr_type === 'superadmin'" class="w15">
                            <input type="text" digitOnly maxlength="2" inputmode="numeric" pattern="[0-9]*" [formControl]="profileForm.get('usr_sessions_done')" matInput>
                        </mat-form-field>
                        <span *ngIf="appState.user.usr_type !== 'superadmin'">
                            {{ profileForm.value.usr_sessions_done || 0 }}
                        </span>
                    </li> -->
                    <li class="m-t-10">
                        מספר ייעוצים שבוצעו:
                        <span class="dl dir-ltr">
                            {{ profileForm?.value?.usr_previous_sessions?.length || 0 }}
                        </span>
                    </li>
                    <li>
                        מספר ייעוצים בחבילה:
                        <mat-form-field *ngIf="appState.user.usr_type === 'superadmin'" class="w15 in-row-field wide">
                            <input type="text" digitOnly maxlength="2" inputmode="numeric" pattern="[0-9]*" [formControl]="profileForm.get('usr_sessions_total')" matInput>
                        </mat-form-field>
                        <span *ngIf="appState.user.usr_type !== 'superadmin'">
                            {{ profileForm.value.usr_sessions_total || 0 }}
                        </span>
                    </li>

                    <li>
                        תאריך הייעוץ הקרוב:
                        <mat-form-field *ngIf="appState.user.usr_type === 'superadmin'" class="w30 in-row-field wide">
                            <input readonly #timingDate [formControl]="profileForm.get('usr_session_date')" matInput [matDatepicker]="dpInput">
                            <mat-datepicker-toggle matSuffix [for]="dpInput"></mat-datepicker-toggle>
                            <mat-datepicker #dpInput disabled="false"></mat-datepicker>
                        </mat-form-field>
                        <span *ngIf="appState.user.usr_type !== 'superadmin'">
                            {{ profileForm.value.usr_session_date | dateFormat : 'DD/MM/yyyy' }}
                        </span>
                    </li>
                    <li>
                        שעת הייעוץ הקרוב:
                        <mat-form-field *ngIf="appState.user.usr_type === 'superadmin'" class="w30 in-row-field wide close-to-line">
                            <input matInput type="time" ampm="false" [value]="profileForm.value.usr_session_hour" [formControl]="profileForm.get('usr_session_hour')" class="new-time-input" />
                        </mat-form-field>
                        <span *ngIf="appState.user.usr_type !== 'superadmin'">
                            {{ profileForm.value.usr_session_hour }}
                        </span>
                    </li>
                    <li class="m-t-10">
                        <mat-radio-group *ngIf="appState.user.usr_type === 'superadmin'" [formControl]="profileForm.get('usr_session_type')">
                            <mat-radio-button value="וידאו">וידאו</mat-radio-button>
                            <mat-radio-button value="פרונטלי">פרונטלי</mat-radio-button>
                        </mat-radio-group>
                        <span *ngIf="appState.user.usr_type !== 'superadmin'">
                            {{ profileForm.value.usr_session_type }}
                        </span>
                    </li>
                    <li *ngIf="profileForm.value._id" class="m-t-20">
                        <button mat-stroked-button color="primary" class="w30" (click)="sessionIsDoneDialog()">הייעוץ בוצע</button>
                    </li>

                    <li class="m-t-30">
                        <div class="font-16 font-bold mb125">ייעוצים קודמים</div>
                        <div class="mb75" *ngFor="let session of ((profileForm?.value?.usr_previous_sessions || []) | reverse);">
                            <span *ngIf="session.usr_session_date">
                                {{ session.usr_session_date | dateFormat : 'DD/MM/yyyy' }}
                            </span>

                            <span> | </span>

                            <span *ngIf="session.usr_session_hour">
                                {{ session.usr_session_hour }}
                            </span>

                            <span> | </span>

                            <span *ngIf="session.usr_session_type">
                                {{ session.usr_session_type }}
                            </span>
                        </div>
                    </li>

                </ul>
            </mat-card>

            <mat-card *ngIf="(appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'player') && profileForm.value.usr_type !== 'parent' && profileForm.value.usr_type !== 'nutri'">
                <mat-error class="custom-error mb50" *ngIf="formInitiated && !(clubsForm.value.extra_field_input_clubs && clubsForm.value.extra_field_input_clubs._id)">
                    {{'profile.SELECT_CLUB_ERROR' | translate}}
                </mat-error>
                <div>
                    <mat-form-field class="w45">
                        <input type="text" [readonly]="appState.user.usr_type !== 'superadmin'" placeholder="{{'profile.SELECT_CLUB_PLACEHOLDER' | translate}}" matInput [formControl]="clubsForm.get('extra_field_input_clubs')" [matAutocomplete]="clubsAutoComplete">
                        <mat-autocomplete #clubsAutoComplete="matAutocomplete" [displayWith]="displayFnClub">
                            <mat-option *ngFor="let option of clubsForm.value.extra_field_clubs_options | async" [value]="option">
                                {{option.clb_name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <ng-container *ngIf="clubsForm.value.extra_field_input_clubs && clubsForm.value.extra_field_input_clubs._id && clubsForm.value.extra_field_input_clubs._id !== '1' && profileForm.value.usr_type === 'player'">
                    <mat-error class="custom-error mb50" *ngIf="formInitiated && !(teamsForm.value.extra_field_input_teams && teamsForm.value.extra_field_input_teams._id)">
                        {{'profile.SELECT_TEAM_ERROR' | translate}}
                    </mat-error>
                    <div>
                        <mat-form-field class="w45">
                            <input type="text" [readonly]="appState.user.usr_type !== 'superadmin' || (appState.user.usr_type === 'player'  && (usr_club_id))" placeholder="{{'profile.SELECT_TEAM_PLACEHOLDER' | translate}}" matInput [formControl]="teamsForm.get('extra_field_input_teams')" [matAutocomplete]="teamsAutoComplete">
                            <mat-autocomplete #teamsAutoComplete="matAutocomplete" [displayWith]="displayFnTeam">
                                <mat-option *ngFor="let option of teamsForm.value.extra_field_teams_options | async" [value]="option">
                                    {{option.tem_name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="appState.user.usr_type === 'superadmin'">
                    <div>
                        <mat-form-field class="w45">
                            <mat-select  placeholder="רמת ספורטאי" [formControl]="profileForm.get('athlete_lvl')">
                                <mat-option value="competitive">ספורטאי תחרותי</mat-option>
                                <mat-option value="social">ספורטאי חברתי</mat-option>
                                <mat-option value="non-athlete">לא ספורטאי</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-container>
            </mat-card>

            <mat-card *ngIf="appState.user.usr_type === 'admin' && appState.user.usr_club_id && profileForm.value.usr_type === 'player'">

                <ng-container *ngIf="appState.user.usr_club_id && profileForm.value.usr_type === 'player'">
                    <mat-error class="custom-error mb50" *ngIf="formInitiated && !(teamsForm.value.extra_field_input_teams && teamsForm.value.extra_field_input_teams._id)">
                        {{'profile.SELECT_TEAM_ERROR' | translate}}
                    </mat-error>
                    <div>
                        <mat-form-field class="w45">
                            <input type="text" placeholder="{{'profile.SELECT_TEAM_PLACEHOLDER' | translate}}" matInput [formControl]="teamsForm.get('extra_field_input_teams')" [matAutocomplete]="teamsAutoComplete">
                            <mat-autocomplete #teamsAutoComplete="matAutocomplete" [displayWith]="displayFnTeam">
                                <mat-option *ngFor="let option of teamsForm.value.extra_field_teams_options | async" [value]="option">
                                    {{option.tem_name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </ng-container>
            </mat-card>

            <mat-card *ngIf="(appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'nutri') && profileForm.value.usr_type === 'nutri'">
                <h3>הגדרות תזונאי</h3>
                <ng-container *ngIf="appState.user.usr_type === 'superadmin'">
                    <div>
                        <mat-slide-toggle formControlName="usr_nutri_autofill" class="mb75">אלגוריתם</mat-slide-toggle>
                    </div>
                    <div>
                        <mat-slide-toggle formControlName="usr_nutri_notes" class="mb75">הצג הערות מערכת</mat-slide-toggle>
                    </div>
                </ng-container>
                <mat-form-field>
                    <textarea formControlName="usr_nutri_intro" matInput cdkTextareaAutosize cdkAutosizeMinRows="5" placeholder="טקסט פתיחה"></textarea>
                </mat-form-field>
            </mat-card>

            <mat-card class="p-0" *ngIf="profileForm.value.usr_type === 'player' && isMinor">

                <mat-accordion [multi]="true" [displayMode]="'flat'">

                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h3>הורה 1</h3>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-container [ngTemplateOutlet]="parentTemplate" [ngTemplateOutletContext]="{          
                            fnameField: 'usr_parent_1_fname',
                            lnameField: 'usr_parent_1_lname',
                            typeField: 'usr_parent_1_type',
                            phoneField: 'usr_parent_1_phone'
                        }">
                        </ng-container>
                    </mat-expansion-panel>

                    <mat-expansion-panel [expanded]="profileForm.value.usr_parent_2_phone != ''">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h3>הורה 2</h3>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-container [ngTemplateOutlet]="parentTemplate" [ngTemplateOutletContext]="{          
                            fnameField: 'usr_parent_2_fname',
                            lnameField: 'usr_parent_2_lname',
                            typeField: 'usr_parent_2_type',
                            phoneField: 'usr_parent_2_phone'
                        }">
                        </ng-container>
                    </mat-expansion-panel>

                </mat-accordion>

                <!-- <h3>הורה 1:</h3>
            <ng-container [ngTemplateOutlet]="parentTemplate" [ngTemplateOutletContext]="{          
                    fnameField: 'usr_parent_1_fname',
                    lnameField: 'usr_parent_1_lname',
                    typeField: 'usr_parent_1_type',
                    phoneField: 'usr_parent_1_phone',
                    emailField: 'usr_parent_1_email'
                }">
            </ng-container>
            <h3>הורה 2:</h3>
            <ng-container [ngTemplateOutlet]="parentTemplate" [ngTemplateOutletContext]="{          
                    fnameField: 'usr_parent_2_fname',
                    lnameField: 'usr_parent_2_lname',
                    typeField: 'usr_parent_2_type',
                    phoneField: 'usr_parent_2_phone',
                    emailField: 'usr_parent_2_email'
                }">
            </ng-container> -->

                <ng-template #parentTemplate let-fnameField="fnameField" let-lnameField="lnameField" let-typeField="typeField" let-phoneField="phoneField" let-emailField="emailField">
                    <div class="font-16">
                        <div class="mb75">
                            <mat-radio-group [formControlName]="typeField">
                                <mat-radio-button value="אבא">אבא</mat-radio-button>
                                <mat-radio-button value="אמא">אמא</mat-radio-button>
                            </mat-radio-group>
                            <mat-error class="custom-error"
                                *ngIf="formInitiated && profileForm.value[typeField] == '' && profileForm.controls[typeField].invalid">
                                עליך לבחור הורה
                            </mat-error>
                        </div>
                        <div class="mb75">
                            <mat-form-field class="w45">
                                <input [formControlName]="fnameField" matInput placeholder="שם פרטי הורה">
                                <mat-error>
                                    {{getFieldError(fnameField)}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="mb75">
                            <mat-form-field class="w45">
                                <input [formControlName]="lnameField" matInput placeholder="שם משפחה הורה">
                                <mat-error>
                                    {{getFieldError(lnameField)}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="mb75">
                            <mat-form-field class="w45">
                                <input type="text" digitOnly maxlength="13" inputmode="numeric" pattern="[0-9]*" [formControlName]="phoneField" matInput placeholder="מספר טלפון של ההורה">
                                <mat-error>
                                    {{getFieldError(phoneField)}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-template>
            </mat-card>

            <mat-card *ngIf="profileForm.value.usr_type === 'parent' && userChildren.length > 0">
                <h3>הילדים שלי:</h3>
                <div>
                    <div class="mb75" *ngFor="let child of userChildren">
                        <span class="font-16 font-bold">{{child.usr_fname}} {{child.usr_lname}}</span>
                        <span class="font-16"> - {{child.usr_phone}}</span>
                    </div>
                </div>
            </mat-card>

            <mat-card *ngIf="(appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'player') && profileForm.value.usr_type === 'player'">
                <div class="row m-0">
                    <div class="centered-row m-0">
                        <h4 class="plan-title nowrap-text">
                            החבילה שלי:
                        </h4>
                        <div class="plan rookie nowrap-text" *ngIf="!profileForm.value.has_plan">
                            Rookie
                        </div>
                        <div class="plan star nowrap-text" *ngIf="profileForm.value.has_plan">
                            All Star
                        </div>
                        <div class="m-l-5 m-r-5" *ngIf="!profileForm.value.has_plan">
                            <mat-icon>add_shopping_cart</mat-icon>
                        </div>
                    </div>
                    <div class="" *ngIf="profileForm.value.has_plan">
                        <span> נותרו לך </span>
                        <span class="font-bold">{{ profileForm.value.reports_left }}</span>
                        <span> דוחות בחבילה </span>
                    </div>
                    <div>

                    </div>
                </div>
            </mat-card>

            <mat-card *ngIf="appState.user.usr_type !== 'admin'">

                <div *ngIf="(profileForm.value.usr_type === 'player' || profileForm.value.usr_type === 'parent') && profileForm.value.usr_share_reports" class="pointer" (click)="openShareReportsDialog(profileForm.value.usr_type)">
                    <h4 class="m-0 m-b-5">{{ 'profile.SHARE_TITLE' | translate }}</h4>
                    <mat-radio-group formControlName="usr_share_reports">
                        <mat-radio-button disabled value="מאשר">{{'profile.ACCEPT' | translate}}</mat-radio-button>
                        <mat-radio-button disabled value="לא מאשר">{{'profile.NOT_ACCEPT' | translate}}</mat-radio-button>
                    </mat-radio-group>
                    <div class="m-b-10">

                    </div>
                    <div class="font-14 font-bold m-b-5">
                        {{'profile.SHARE_A' | translate}}
                    </div>
                    <div class="font-14 font-italic m-b-5">
                        {{'profile.SHARE_B' | translate}}
                    </div>
                    <div class="font-14 font-italic m-b-5">
                        {{'profile.SHARE_C' | translate}}
                    </div>
                </div>

                <div class="mt125 mb125">
                    <mat-checkbox class="checkbox" formControlName="usr_subscribe"><h4 class="m-0">{{'profile.SUBSCRIBE' | translate}}</h4></mat-checkbox>
                    <div class="m-b-10">

                    </div>
                    <div class="font-14 font-bold m-b-5">
                        {{'profile.SUBSCRIBE_A' | translate}}
                    </div>
                    <div class="font-14 font-italic m-b-5">
                        {{'profile.SUBSCRIBE_B' | translate}}
                    </div>
                    <div class="font-14 font-italic m-b-5">
                        {{'profile.SUBSCRIBE_C' | translate}}
                    </div>
                </div>

                <div class="mt125 mb125">
                    <mat-checkbox class="checkbox" formControlName="usr_agreement">{{'profile.ACCEPT' | translate}} <span class="ul-text pointer" (click)="openTerms($event)">{{ 'profile.TERMS' | translate }}</span></mat-checkbox>
                    <mat-error class="custom-error" *ngIf="formInitiated && profileForm.value.usr_agreement == false">
                        {{ 'profile.HAVE_TO_ACCEPT' | translate }}
                    </mat-error>
                </div>

            </mat-card>

            <div class="mat-error custom-error text-center m-b-15" *ngIf="appState.user.usr_type === 'player' && !appState.user['usr_confirmed']">
                {{'profile.NOT_ACCESS_ATTENTION' | translate}}
            </div>

            <button type="button" (click)="onSubmit()" mat-raised-button color="primary" [disabled]="isBusy">{{'general.save' | translate}}</button>

            <!-- <button *ngIf="appState.user.usr_type === 'superadmin'" type="button" (click)="submitFormAdminDialog()" mat-raised-button color="primary" [disabled]="isBusy">{{'general.save' | translate}}</button> -->

            <!-- <button *ngIf="appState.user.usr_type !== 'superadmin'" type="button" (click)="submitFormUser()" mat-raised-button color="primary" [disabled]="isBusy">{{'general.save' | translate}}</button> -->

        </ng-container>

    </form>

</div>

<ng-template #subscribeTemplate let-form="form">
    <div class="basic-form mat-body" [formGroup]="form">
        <div class="mb50">{{ 'profile.SUBSCRIBE_SUBTITLE_A' | translate }}</div>
        <div class="mb50">{{ 'profile.SUBSCRIBE_SUBTITLE_B' | translate }}</div>
        <div class="mb50">{{ 'profile.SUBSCRIBE_SUBTITLE_C' | translate }}</div>
        <mat-checkbox class="checkbox" formControlName="subscribe"><h4>{{ 'profile.SUBSCRIBE' | translate }}</h4></mat-checkbox>
        <!-- <div class="text-success font-14" [ngClass]="{'font-bold': formInitiated && !profileForm.value.usr_subscribe}">
            {{'profile.SUBSCRIBE_B' | translate}}
        </div> -->
    </div>
</ng-template>
